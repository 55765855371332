export default () => ({
    template: `
        <input type="checkbox" ng-model="ctrl.checkbox" ng-change="ctrl.ngModel.$setViewValue(ctrl.checkbox)" ng-disabled="ctrl.disabled">
    `,
    require: {
        "ngModel": "ngModel"
    },
    scope: {
        productId: "=",
        disabled: "="
    },
    bindToController: true,
    controllerAs: "ctrl",
    controller: class {
        public ngModel;
        public productId;
        public checkbox;

        $onInit() {
            this.ngModel.$formatters.push((x) => {
                return this.toArray(x).indexOf(this.productId) >= 0;
            })
            this.ngModel.$parsers.push((x) => {
                let modelArray = this.toArray(this.ngModel.$modelValue);
                if (!x) return modelArray.filter(p => p != this.productId);
                if (x && !(modelArray.indexOf(this.productId) >= 0)) return modelArray.concat([this.productId]);
                return this.ngModel.$modelValue;
            })
            this.ngModel.$render = () => {
                this.checkbox = this.ngModel.$viewValue;
            }
        }

        toArray(itemOrArray) {
            if (typeof itemOrArray === "string") return [itemOrArray];
            return itemOrArray;
        }
    },
})
