import ApiService from "../api/ApiService";

export default class TemplateListItemCtrl {

    public link;

    constructor(private ApiService: ApiService, private $scope, public AuthService) {
    }

    download() {
        this.ApiService.postForm("/api/templates/" + this.$scope.app.id + "/download/" + this.$scope.app.name, null);
    }

    share() {
        this.link = "https://" + window.location.hostname + "/share/store/" + this.$scope.app.id;
        navigator.clipboard.writeText(this.link);
    }
}
