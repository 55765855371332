import * as angular from "angular";
import FormsCtrl from "./FormsCtrl";
import FormsSettingsCtrl from "./FormsSettingsCtrl";
import FormsService from "./FormsService";

export default angular.module("inphinity.app.forms", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("forms", {
                url: "/forms",
                templateUrl: "html/forms.html",
                controllerAs: "ctrl",
                controller: FormsCtrl,
                resolve: FormsCtrl.$resolve
            })
    })
    .config(function routes($stateProvider) {
        $stateProvider
            .state("forms-settings", {
                url: "/forms/:id/settings",
                template: require("./forms-settings.html").default,
                controllerAs: "ctrl",
                controller: FormsSettingsCtrl,
                resolve: FormsSettingsCtrl.$resolve
            })
    })
    .directive("formsUploadField", function () {
        return {
            restrict: "A",
            scope: {
                'formsUploadField': "&"
            },
            transclude: true,
            link: (scope, element) => {
                element.on('change', (e) => {
                    scope.$apply(() => {
                        scope.formsUploadField({$event: e});
                    })
                })
                scope.$on("$destroy", () => element.off("change"))
            }
        };
    })
    .service("FormsService", FormsService)
    .name;
