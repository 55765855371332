import DialogService from "../dialogs/DialogService";
import {LoginInfo, UserInfo} from "../../login/types";

export default class UnifiedAuthService {


    private defferedLogin: { promise: Promise<UserInfo>, resolve: (result: UserInfo | false) => void };
    private user: UserInfo | undefined;
    private accessToken: string | undefined;
    private expiresAt: number | undefined;
    private targetHash: string | undefined;

    constructor(private $q, private $http, private $rootScope, private DialogService: DialogService) {
        this.defferedLogin = $q.defer();
    }

    async run() {
        this.defferedLogin.resolve(await this.loadSession());
    }

    public login(targetHash?: string) {
        this.targetHash = targetHash || window.location.hash;
        window.location.hash = "#!/login";
    }

    public async requestLogin(username: string, password: string): Promise<LoginInfo[]> {
        return (await this.$http.post("/api/login/login", {username, password})).data;
    }

    public async processLogin(data: LoginInfo) {
        this.user = data.user;
        this.defferedLogin = this.$q.defer();
        this.defferedLogin.resolve(this.user);
        this.accessToken = data.token.access_token;
        this.expiresAt = (data.token.expires_in * 1000) + new Date().getTime();
        window.location.hash = this.targetHash || "";
        this.targetHash = undefined
        try {
            sessionStorage.setItem("inphinity_token", this.accessToken);
            sessionStorage.setItem("inphinity_token_exp", this.expiresAt.toString());
        } catch (e) {
            console.log("failed to store tokens to session storage", e);
        }
    }

    async loadSession(): Promise<UserInfo | false> {
        try {
            const token = sessionStorage.getItem("inphinity_token");
            const exp = sessionStorage.getItem("inphinity_token_exp");
            if (!token) {
                return false;
            }
            const resp = await this.$http.get("/api/login/userinfo", {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            this.user = resp.data;
            this.accessToken = token;
            this.expiresAt = +exp;
            this.$rootScope.$digest();
            return this.user;
        } catch (e) {
            console.log("failed to load token from session storage", e);
        }
        return false;
    }

    logout() {
        this.accessToken = '';
        this.expiresAt = 0;
        this.user = null;
        try {
            sessionStorage.removeItem("inphinity_token");
            sessionStorage.removeItem("inphinity_token_exp");
        } catch (e) {

        }
        window.location.href = window.location.protocol + "//" + window.location.host
    }

    waitLogin() {
        return this.defferedLogin.promise;
    }

    // returns user identity info if exists
    getUser() {
        return this.user;
    }

    getAccessToken() {
        return this.accessToken;
    }

    isLoggedIn() {
        return !!this.user;
    }


}