import {Worker} from "../util";
import * as countries from "../util/countries";
import SettingsService from "./SettingsService";
import AuthService from "../auth/AuthService"
import AppService from "../apps/AppService";
import FormsService from "../forms/FormsService";

export default class ProfileCtrl {

    static $resolve = {
        profile: (SettingsService, UserService, $stateParams) => {
            if (!$stateParams.id) {
                return SettingsService.getProfile();
            } else {
                return UserService.getUser($stateParams.id);
            }
        },
        currentUser: (AuthService) => AuthService.waitLogin(),
        apps: (AppService: AppService) => AppService.getApps(),
        forms: (FormsService: FormsService) => FormsService.getForms()
    }

    public profile;
    public profileMissing = false;
    public countries = countries;
    public userPassword = {password: null, password2: null};
    public appIdMap;
    public formIdMap;
    public roles;

    private admin;
    private formsAdmin;
    private owner

    private role = 'User';
    private changeRoleDisabled = false;

    public batchRead = false;
    public batchReadWrite = false;
    public batchModerate = false;


    constructor(private SettingsService: SettingsService, private AuthService: AuthService, private AppService: AppService, private UserService, private FormsService: FormsService, profile, apps, forms, private currentUser) {
        this.admin = SettingsService.isAdmin();
        this.owner = SettingsService.isOwner();
        this.formsAdmin = SettingsService.isFormsAdmin();

        if (!profile) {
            profile = {
                id: currentUser.sub,
                name: currentUser.name,
                email: currentUser.email,
                isOwner: true,
                isAdmin: true,
                isFormsAdmin: false,
                tenant: {name: ""}
            };
            this.admin = true;
            this.owner = true;
            this.formsAdmin = false;
            this.profileMissing = true;
        }

        this.save = Worker(this.save.bind(this));
        this.changePassword = Worker(this.changePassword.bind(this));
        this.updatePermissions = Worker(this.updatePermissions.bind(this));
        this.updateFormsPermissions = Worker(this.updateFormsPermissions.bind(this));
        this.copyToken = Worker(this.copyToken.bind(this));
        this.showProfile(profile);
        this.showApps(apps);
        this.showForms(forms);

        // current user options
        if (this.owner) this.roles = ["User", "Admin", "Owner"];
        else if (this.admin) this.roles = ["User", "Admin"];
        else this.roles = ["User"];

        if (!this.roles.includes(this.role)) {
            this.roles.push(this.role);
            this.changeRoleDisabled = true;
        }
        if (!this.profile.id || currentUser.sub == this.profile.id) {
            this.changeRoleDisabled = true;
        }

        this.initializeBatchCheckboxes();

    }

    roleModified(r) {
        this.profile.isAdmin = r == "Admin" || r == "Owner";
        this.profile.isOwner = r == "Owner";
    }

    async save() {
        await this.SettingsService.saveUserProfile(this.profile);
        if (this.profile.isOwner) {
            await this.SettingsService.saveTenantProfile(this.profile.tenant);
        }
        await this.SettingsService.getCachedProfile();
        this.profileMissing = false;
    }

    async changePassword() {
        if (this.userPassword.password !== this.userPassword.password2) throw "Passwords do not match";
        var r = await this.SettingsService.setUserPassword(this.profile.id, this.userPassword.password);
        this.userPassword.password = this.userPassword.password2 = "";
        return r;
    }

    async updatePermissions() {
        let err;
        try {
            await this.SettingsService.updatePermissions(this.profile.appPermissions);
        } catch (e) {
            err = e;
        }
        this.showProfile(await this.UserService.getUser(this.profile.id));
        this.showApps(await this.AppService.getApps());
        if (err) throw err;
    }

    isAdmin() {
        return this.admin;
    }

    isOwner() {
        return this.owner;
    }

    isFormsAdmin() {
        return this.formsAdmin;
    }

    isAdminOrFormsAdmin() {
        return this.formsAdmin || this.admin;
    }

    showProfile(profile) {
        this.profile = profile;
        this.role = this.profile.isOwner ? "Owner" : this.profile.isAdmin ? "Admin" : "User"
    }

    showApps(apps) {
        apps = apps.filter(app => app.subscription.active == 1)
        this.appIdMap = apps.reduce((map, app) => {
            map[app.id] = app;
            return map;
        }, {});
        apps.forEach(app => {
            if (!this.profile.appPermissions.find(perm => perm.appInstanceId == app.id)) {
                this.profile.appPermissions.push({
                    userId: this.profile.id,
                    appInstanceId: app.id,
                    read: false,
                    modify: false,
                    reload: false
                })
            }
        })
    }

    showForms(forms) {
        //forms = forms.filter(form => app.subscription.active == 1)
        this.formIdMap = forms.reduce((map, form) => {
            map[form.formId] = form;
            return map;
        }, {});
        forms.forEach(form => {
            if (!this.profile.formPermissions.find(perm => perm.formId == form.formId)) {
                this.profile.formPermissions.push({
                    userId: this.profile.id,
                    formId: form.formId,
                    read: false,
                    readWrite: false,
                    moderate: false
                })
            }
        })
        console.log(this.formIdMap)
    }

    async updateFormsPermissions() {
        let err;
        try {
            await this.SettingsService.updateFormsPermissions(this.profile.formPermissions);
        } catch (e) {
            err = e;
        }
        this.showProfile(await this.UserService.getUser(this.profile.id));
        this.showForms(await this.FormsService.getForms());
        if (err) throw err;
    }

    async copyToken() {
        let input = document.createElement("input");
        document.body.appendChild(input);
        input.value = await this.SettingsService.getUserToken();
        input.select();
        console.log(document.execCommand("copy"));
        document.body.removeChild(input);
        alert("API token was copied");
    }

    initializeBatchCheckboxes() {
        this.batchRead = this.profile.formPermissions.every(p => p.read);
        this.batchReadWrite = this.profile.formPermissions.every(p => p.readWrite);
        this.batchModerate = this.profile.formPermissions.every(p => p.moderate);
    }

    toggleBatch(type: 'read' | 'readWrite' | 'moderate', value: boolean) {
        this.profile.formPermissions.forEach(permission => {
            if (type === 'read') {
                permission.read = value;
                if (!value) {
                    permission.readWrite = false;
                    permission.moderate = false;
                }
            } else if (type === 'readWrite') {
                permission.readWrite = value;
                if (value) permission.read = true;
                else permission.moderate = false;
            } else if (type === 'moderate') {
                permission.moderate = value;
                if (value) {
                    permission.read = true;
                    permission.readWrite = true;
                }
            }
        });
        this.initializeBatchCheckboxes();
    }
}
