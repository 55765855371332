const AUTO_REFRESH_TIME = 2000;

export default class AppManagementCtrlBase {
    static $resolve = {};

    public apps = [];
    public autoRefreshTimer = false;
    public appsLoaded = false;
    public datamodels = [];

    private trialHideTime;

    public constructor(protected config, protected DialogService, protected AppService, protected $timeout, protected ApiService) {
    }

    async deleteApp(app) {
        let result = await this.DialogService.confirm("Delete " + app.name + "?");
        if (result) {
            app.deletePending = true;
            if (app.tenant) {
                await this.AppService.deleteApp(app.id, app.tenant);
            } else {
                await this.AppService.deleteApp(app.id, null);
            }

            await this.reloadAppList();
        }
    }

    async upgradeApp(app) {
        let result = await this.DialogService.confirm("Upgrade " + app.name + "?");
        if (result) {
            app.upgradePending = true;
            await this.AppService.upgradeApp(app.id);
            app.upgradePending = false;
            await this.reloadAppList();

        }
    }

    async reloadApp(app) {
        app.reload.status = 1;
        await this.AppService.reloadApp(app.id);
        this.reloadAppList();
    }

    async reloadAppList() {
        if (!this.appsLoaded) {
            //await this.$timeout(2000);
        }

        if (this.autoRefreshTimer) {
            this.$timeout.cancel(this.autoRefreshTimer);
            this.autoRefreshTimer = false;
        }

        this.trialHideTime = new Date().setDate(new Date().getDate() - 30);

        const all = await this.AppService.getApps();
        const connectors = all.filter(app => app.template.type == "Connector" && !(app.subscription.active == 0 && app.subscription.trial == 1 && new Date(app.subscription.deactivationTime) < this.trialHideTime));
        const apps = all.filter(app => app.template.type == "App" && !(app.subscription.active == 0 && app.subscription.trial == 1 && new Date(app.subscription.deactivationTime) < this.trialHideTime))

        function connectorBelongsToApp(connector, app) {
            return connector.template.dataModel == app.template.dataModel || app.template.auxDataModels.includes(connector.template.dataModel);
        }


        // add connector info for apps
        this.apps = apps.map(app => ({
            ...app,
            connectors: connectors.filter(connector => connectorBelongsToApp(connector, app))
        }));

        const orphanedConnectors = connectors.filter(conn => !apps.find(app => connectorBelongsToApp(conn, app)));
        if (orphanedConnectors.length > 0) {
            this.apps.push(({
                id: "NONE",
                name: "Connectors without APP",
                connectors: orphanedConnectors
            }))
        }

        // autorefresh if reload is pending
        if (all.find(app => app.reload && this.getReloadStatusInfo(app.reload.status).busy)) {
            this.autoRefreshTimer = this.$timeout(() => {
                this.reloadAppList()
            }, AUTO_REFRESH_TIME);
        }
        this.appsLoaded = true;

        const models = new Set();
        this.apps
            .filter(app => app.template)
            .map(app => [app.template.dataModel].concat(app.template.auxDataModels))
            .forEach(modelList => modelList.forEach(model => models.add(model)));

        this.datamodels = Array.from(models).map(model => ({
            id: model,
            apps: apps.filter(app => app.template && (app.template.dataModel == model || app.template.auxDataModels.includes(model))),
            connectors: connectors.filter(app => app.template && (app.template.dataModel == model || app.template.auxDataModels.includes(model))),
        }))
    }

    getAppUrl(app) {
        return app.url;
    }

    openApp(app) {
        if (app.subscription.active == 0) {
            this.DialogService.warning("Your app do not have active subscription.");
        } else if (app.permission.read) {
            window.open(app.url);
        } else {
            this.DialogService.warning("You do not have access to this app.");
        }
    }

    getReloadStatusInfo(status) {
        return this.AppService.getReloadStatusInfo(status);
    }

    shouldShowReloadTime(app) {
        return this.AppService.shouldShowReloadTime(app);
    }

    async deleteDataModel(model) {
        if (!await this.DialogService.confirm("Really delete all data in datamodel " + model.id + "?")) {
            return;
        }
        model.deletePending = true;
        try {
            console.log(await this.AppService.deleteDataModel(model.id));
        } catch (e) {
            model.deletePending = false;
            throw e;
        }
        model.deletePending = false;
    }

    async resetForms(app) {
        let result = await this.DialogService.confirm("Reset forms data to default?");
        if (result) {
            try {
                await this.ApiService.post("/api/apps/" + app.id + "/resetForms", null);
                await this.DialogService.success("Forms data was reset.");
            } catch (e) {
                await this.DialogService.error("Unable to reset forms, please contact support.");
            }
        }
    }
}
