import * as $ from 'jquery';

// card collapsible
$(document).on('click', '.card [data-action=collapse]:not(.disabled)', function (e) {
    console.log("toggling", this);
    const $target = $(this),
        slidingSpeed = 150;

    e.preventDefault();
    $target.parents('.card').toggleClass('card-collapsed');
    $target.toggleClass('rotate-180');
    $target.closest('.card').children('.card-header').nextAll().slideToggle(slidingSpeed);
});


// Sidebar navigation collapsible
const _navigationSidebar = function () {

    // Define default class names and options
    const navClass = 'nav-sidebar',
        navItemClass = 'nav-item',
        navItemOpenClass = 'nav-item-open',
        navLinkClass = 'nav-link',
        navSubmenuClass = 'nav-group-sub',
        navSlidingSpeed = 250;

    $(document).on("click", ".nav-sidebar .nav-item.nav-item-submenu > .nav-link:not(.disabled)", function (e) {
        e.preventDefault();
        // Simplify stuff
        const $target = $(this),
            $navSidebarMini = $('.sidebar-xs').not('.sidebar-mobile-main').find('.sidebar-main .' + navClass).children('.' + navItemClass);
        // Collapsible
        if ($target.parent('.' + navItemClass).hasClass(navItemOpenClass)) {
            $target.parent('.' + navItemClass).not($navSidebarMini).removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
        } else {
            $target.parent('.' + navItemClass).not($navSidebarMini).addClass(navItemOpenClass).children('.' + navSubmenuClass).slideDown(navSlidingSpeed);
        }
        // Accordion
        if ($target.parents('.' + navClass).data('nav-type') == 'accordion') {
            $target.parent('.' + navItemClass).not($navSidebarMini).siblings(':has(.' + navSubmenuClass + ')').removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
        }
    });
}

_navigationSidebar();

export default {};
