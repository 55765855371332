import {debounce, Worker} from "../../util";
import {Pagination} from "../Pagination";

const ITEMS_PER_PAGE = 10;

export default class UsersCtrl {
    public siteAdmin;
    public users = [];
    public err;
    public r;
    public newUser;
    public newTenant;
    public portals = [];
    public pagination: Pagination;
    public userSearch = {};
    public tenantSearch = {};

    constructor(private ApiService, private DialogService, private UserService, private SettingsService) {
        this.siteAdmin = this.SettingsService.isSiteAdmin();
        this.load = Worker(this.load.bind(this));
        this.deleteUser = Worker(this.deleteUser);
        this.disableUser = Worker(this.disableUser);
        this.enableUser = Worker(this.enableUser);
        this.inviteUser = Worker(this.inviteUser);
        this.searchChanged = debounce(250, this.searchChanged);
        this.listPortals();

        this.newUser = {
            tenantId: "",
            name: "",
            email: "",
            isOwner: 0,
            isAdmin: 0,
            skipSendMail: 0
        };

        this.newTenant = {
            create: 0,
            name: "",
            billingAddress: "<temp>",
            country: "XX",
            portalInstanceId: this.portals[0],
            industry: "<temp>"
        };

        this.pagination = new Pagination(this.load.bind(this), ITEMS_PER_PAGE);
        void this.load();
    }

    searchChanged() {
        this.pagination.current = 1;
        return this.load();
    }

    async load() {
        let {data} = await this.ApiService.get("/api/admin/users?offset=" + this.pagination.offset + "&tenantSearch=" + encodeURIComponent(JSON.stringify(this.tenantSearch)) + "&userSearch=" + encodeURIComponent(JSON.stringify(this.userSearch)) + "&limit=" + ITEMS_PER_PAGE);
        this.pagination.total = data.totalCount;
        this.users = data.result;
    }

    async listPortals() {
        this.portals = (await this.ApiService.get("/api/messages/portals")).data
    }

    async deleteUser(tenant, user) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Delete " + user.name + " from tenant " + tenant.name + "? User will be deleted.");
        if (result) {
            user.deletePending = true;
            try {
                this.r = (await this.ApiService.delete("/api/admin/tenant/" + tenant.id + "/user/" + user.id, null)).data
            } catch (e) {
                this.err = e.data.message;
                user.deletePending = false;
            }
            await this.load();
        }
    }

    async disableUser(tenant, user) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Disable " + user.name + " ? User will be disabled for a quarantine period, then deleted.");
        if (result) {
            user.disablePending = true;
            try {
                this.r = (await this.ApiService.post("/api/admin/tenant/" + tenant.id + "/user/" + user.id + "/disable", null)).data
            } catch (e) {
                this.err = e.data.message;
                user.disablePending = false;
            }
            await this.load();
        }
    }

    async enableUser(tenant, user) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Re-enable user " + user.name + " ?");
        if (result) {
            user.enablePending = true;
            try {
                this.r = (await this.ApiService.post("/api/admin/tenant/" + tenant.id + "/user/" + user.id + "/enable", null)).data
            } catch (e) {
                this.err = e.data.message;
                user.enablePending = false;
            }
            await this.load();
        }
    }

    async inviteUser(tenantId) {
        this.err = this.r = "";
        this.newUser.invitePending = true;
        this.newUser.tenantId = tenantId;

        try {
            this.r = (await this.ApiService.post("/api/admin/user/invite", this.newUser)).data;
        } catch (e) {
            this.err = e.data.message;
        }
        this.newUser.invitePending = false;

        this.newUser = {
            tenantId: "",
            name: "",
            email: "",
            isOwner: 0,
            isAdmin: 0,
            isFormsAdmin: 0,
            skipSendMail: 0
        };
        await this.load();
    }

    async createTenant() {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Create new tenant " + this.newTenant.name + " ?");
        if (result) {
            this.newTenant.createPending = true;
            try {
                this.r = (await this.ApiService.post("/api/admin/tenant/new", this.newTenant)).data;
            } catch (e) {
                this.err = e.data.message;

            }
            this.newTenant.createPending = false;
            this.tenantSearch[`name`] = this.newTenant.name;
            this.newTenant = {
                create: 0,
                name: "",
                billingAddress: "<temp>",
                country: "XX",
                portalInstanceId: this.portals[0],
                industry: "<temp>"
            };
            await this.load();
        }
    }

    async resetPassword(user) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Reset password and resend invitaion mail for user " + user.name + " ?");
        if (result) {
            user.resetPending = true;
            try {
                this.r = (await this.ApiService.post("/api/admin/user/" + user.id + "/resetPassword", null)).data;
                //this.Ur = await this.UserService.resetPassword(user.id);
            } catch (e) {
                this.err = e.data.message;
                user.resetPending = false;
            }
            user.resetPending = false;
            // await this.load();
        }
    }

    async deleteTenant(tenant) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Delete " + tenant.name + " ? Tenant will be deleted.");
        if (result) {
            tenant.deletePending = true;
            try {
                this.r = (await this.ApiService.delete("/api/admin/tenant/" + tenant.id, null)).data
            } catch (e) {
                this.err = e.data.message;
                tenant.deletePending = false;
            }
            await this.load();
        }
    }

    async updateUserLimit(tenant) {
        tenant.updateLimitPending = true;
        var update = {
            tenantId: tenant.id,
            limit: tenant.newUserLimit
        }
        var r = (await this.ApiService.post("/api/admin/users/limit", update)).data

        tenant.updateLimitPending = false
        await this.load();
    }

    async updateUserFormsLimit(tenant) {
        tenant.updateFormsLimitPending = true;
        var update = {
            tenantId: tenant.id,
            limit: tenant.newFormsUserLimit
        }

        var r = (await this.ApiService.post("/api/admin/users/formsLimit", update)).data

        tenant.updateFormsLimitPending = false
        await this.load();
    }
}
