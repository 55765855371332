import {Worker} from "../../util";

export default class MessagesCtrl {
    public messages = [];
    public err;
    public r;
    public portals = [];

    private newMessage;

    constructor(private ApiService, private AppService, private DialogService, private UserService, private MessageService) {
        this.load = Worker(this.load);
        this.sendNotifications = Worker(this.sendNotifications);
        this.load();
        this.listPortals();

        this.newMessage = {
            subject: "",
            text: "",
            subtype: "",
            filter: {},
            Key: null,
            f: "ALL",
            portals: ["ALL"],
            owner: true,
            admin: false,
            user: false,
        }
    }

    async listPortals() {
        this.portals = (await this.ApiService.get("/api/messages/portals")).data
    }

    async load() {
        this.messages = await this.MessageService.getAllNotifications();
    }

    async sendNotifications() {
        let filter = {};
        if (this.newMessage.f == 'ALL') {
            filter = '*'
        } else if (this.newMessage.f == 'Tenant') {
            if (this.newMessage.tenants == "*" || this.newMessage.tenants == undefined) {
                filter = {
                    tenantId: -1,
                    owner: this.newMessage.owner,
                    admin: this.newMessage.admin,
                    user: this.newMessage.user
                }
            } else {
                filter = {
                    tenantId: JSON.parse("[" + this.newMessage.tenants + "]"),
                    owner: this.newMessage.owner,
                    admin: this.newMessage.admin,
                    user: this.newMessage.user
                }
            }
        } else if (this.newMessage.f == 'User') {
            filter = {userId: (this.newMessage.users).split(",")}
        }

        this.newMessage.filter = filter;
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Send " + this.newMessage.subject + " ?");
        if (result) {
            this.newMessage.sendPending = true;
            try {
                this.r = (await this.MessageService.sendNotifications(this.newMessage));
            } catch (e) {
                this.err = e.data.message;
            }
            this.newMessage.sendPending = false;
            await this.load();
        }
    }

    async selectPortal(portal: string) {
        if (portal == 'ALL') {
            if (this.newMessage.portals.includes("ALL")) {
                var index = this.newMessage.portals.indexOf("ALL");
                if (index !== -1) this.newMessage.portals.splice(index, 1);
            } else {
                this.newMessage.portals = ["ALL"];
            }
            return;
        }

        if (this.newMessage.portals.includes(portal)) {
            var index = this.newMessage.portals.indexOf(portal);
            if (index !== -1) this.newMessage.portals.splice(index, 1);

            if (this.newMessage.portals.length == 0) {
                this.newMessage.portals.push("ALL");
            }

        } else {
            if (this.newMessage.portals.includes("ALL")) {
                var index = this.newMessage.portals.indexOf("ALL");
                if (index !== -1) this.newMessage.portals.splice(index, 1);
            }
            this.newMessage.portals.push(portal);
        }
    }
}
