import {Worker} from "../../util";
import cronstrue from 'cronstrue';

export default class SchedulerCtrl {
    public tasks = [];

    constructor(private ApiService) {
        this.load = Worker(this.load);
        this.run = Worker(this.run);
        this.load();
    }

    async load() {
        this.tasks = (await this.ApiService.get("/api/admin/scheduler")).data
    }

    async run(task) {
        await this.ApiService.post("/api/admin/scheduler/" + task.id);
        await this.load();
    }

    getTaskSchedule(schedule) {
        if (!schedule) return ""
        return cronstrue.toString(schedule, {use24HourTimeFormat: true});
    }

}
