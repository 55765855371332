export default class FormsService {
    constructor(private ApiService) {
    }

    async getForms() {
        return (await this.ApiService.get("/api/forms")).data;
    }

    async getForm(id) {
        return (await this.ApiService.get("/api/forms/" + id)).data;
    }

    async getFormPermissions(id: string) {
        return (await this.ApiService.get("/api/forms/" + id + "/permissions")).data;
    }

    async createToken(id: string) {
        return (await this.ApiService.post("/api/forms/" + id + "/token")).data;
    }

    async getQlikclouddomain() {
        return (await this.ApiService.get("/api/forms/qlikclouddomain")).data;
    }

    async setQlikclouddomain(tenantId: number, qlikclouddomain: string) {
        return (await this.ApiService.post("/api/forms/qlikclouddomain", {tenantId, qlikclouddomain})).data;
    }

    async setDatabaseConfig(id, config) {
        return (await this.ApiService.post("/api/forms/" + id + "/database", config)).data;
    }

    async updateCleanupConfig(id, config) {
        return (await this.ApiService.post("/api/forms/" + id + "/purge", config)).data;
    }

    async getFormPublicKey(id) {
        return (await this.ApiService.get("/api/forms/" + id + "/public.key")).data;
    }

    async testForm(id) {
        return (await this.ApiService.post("/api/forms/" + id + "/test")).data;
    }

    async importData(id, data) {
        return (await this.ApiService.postXml("/api/forms/" + id + "/import", data)).data;
    }

    async getFormGroups(id: string) {
        return (await this.ApiService.get("/api/forms/" + id + "/groups")).data;
    }

    async updateFormGroups(id: string, groups: []) {
        return (await this.ApiService.post("/api/forms/" + id + "/groups", groups)).data;
    }

}
