import {Worker} from "../../util";
import * as moment from "moment";

export default class LicensesPartnerCtrl {
    public licenses = [];
    public newTrialData;
    public err;
    public generatedLicense;
    public r;
    public mail;
    public hostname = window.location.host;

    public products = ["Forms", "inphinity-flow"];

    constructor(private ApiService, private DialogService, private UserService, private SettingsService, $state) {
        this.load = Worker(this.load);
        this.newTrial = Worker(this.newTrial);
        this.load();

        this.newTrialData = {
            computerId: $state.params.computerId || "",
            product: $state.params.product || this.products[0],
            company: $state.params.company || "",
            description: $state.params.note || "",
            version: $state.params.version || "8.0.0",
            name: $state.params.customer || "",
            email: $state.params.email || "",
            exDate: ""
        };

        if ($state.params.computerId != undefined) {
            this.newTrialData.create = 1;
        }

        this.mail = {
            computerId: 0,
            key: "",
            exDate: null,
        };

        this.generatedLicense = false;
    }

    async load() {
        this.licenses = (await this.ApiService.get("/api/licenses/myLicenses")).data
    }

    async newTrial() {
        this.reset();

        let isComputerIdRequired = this.newTrialData.product != "inphinity-flow";
        let isLimitRequired = this.newTrialData.product != "inphinity-flow";

        if ((isComputerIdRequired && this.newTrialData.computerId == '0') || this.newTrialData.company == '' || this.newTrialData.name == '' || this.newTrialData.email == '') {
            this.err = "Missing customer informations.";
            return;
        }
        if (isComputerIdRequired && isNaN(this.newTrialData.computerId)) {
            this.err = "Please enter correct computerId.";
            return;
        }

        this.newTrialData.Pending = true;
        this.newTrialData.exDate = moment().add(14, 'days').toDate();
        this.newTrialData.date = moment(this.newTrialData.exDate).format("DD-MM-YYYY");

        try {

            var key = (await this.ApiService.post("/api/licenses/trial", this.newTrialData)).data;
            this.generatedLicense = {
                ...this.newTrialData,
                key
            };
        } catch (e) {
            this.err = e.data.message;
        }

        this.newTrialData.Pending = false;

        this.newTrialData = {
            product: this.products[0],
            computerId: "",
            company: "",
            description: "",
            name: "",
            email: "",
            exDate: "",
            trialType: "Trial 2W",
            version: this.newTrialData.version,
        };
        await this.load();
    }

    /*

      async newTrial2() {
        this.err = this.r = "";
        this.mail = {
          computerId: "",
          key: "",
          exDate: null,
        };

        if (this.newTrialData.computerId == '0' || this.newTrialData.company == '' || this.newTrialData.name == '' || this.newTrialData.email == '') {
          this.err = "Missing required customer informations.";
          return;
        }
        if (isNaN(this.newTrialData.computerId)) {
          this.err = "Please enter correct computerId.";
          return;
        }
        this.newTrialData.Pending = true;
          try {
          var key = (await this.ApiService.post("/api/licenses/formsTrial", this.newTrialData)).data;
          this.r = "New trial license for computerId " + this.newTrialData.computerId + " is " + key;
          this.mail.key = key;
          this.mail.exDate = new Date(Date.now() + 12096e5);
          this.mail.computerId = this.newTrialData.computerId;
          }catch(e){
           this.err = e.data.message;
        }

        this.newTrialData.Pending = false;

        this.newTrialData = {
          computerId: "",
          company: "",
          description: "",
          name: "",
          email: "",
        };
          await this.load();
      }

    */

    reset() {
        this.err = "";
        this.generatedLicense = undefined;
    }
}
