import LicensesCtrl from "./LicensesCtrl"

export default class LicensesPartnerCtrl extends LicensesCtrl {
    constructor(SettingsService, ApiService, DialogService, $state) {
        super(SettingsService, ApiService, DialogService, $state);
        this.init();
    }

    async init() {
        this.applyPreset(this.presets[0]);
        this.lic.partner = (await this.SettingsService.getProfile()).tenant.name;
    }

    async load() {
        let { data } = await this.ApiService.get("/api/licenses/suite/myList?offset=" + this.pagination.offset + "&search=" + encodeURIComponent(JSON.stringify(this.search)));
        this.pagination.total = data.totalCount;
        this.licenses = data.results;
    }

    async generate() {
        console.log("partner")
        this.result = (await this.ApiService.post("/api/licenses/suite/generateTrial", {
            lic: this.lic,
            key: this.key
        })).data;
        void this.load();
        this.init();
    }

    extendLicense(lic) {
        super.extendLicense(lic);
        this.init();
    }
}
