import * as angular from "angular";
import TenantsCtrl from "./TenantsCtrl";

export default angular.module("inphinity.app.admin.tenants", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("admin-tenants", {
                url: "/admin/tenants?id",
                template: require("./tenants.html").default,
                controllerAs: "ctrl",
                controller: TenantsCtrl,
            })
    })
    .name;
