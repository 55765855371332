import * as angular from "angular";
import MessageService from "./MessageService";
import NotificationDropdownCtrl from "./NotificationDropdownCtrl";

export default angular.module("inphinity.app.messages", [])
    .service("MessageService", MessageService)
    .directive("mspNotificationDropdown", function () {
        return {
            restrict: "E",
            template: require("./NotificationDropdown.html").default,
            replace: true,
            scope: true,
            controllerAs: "ctrl",
            controller: NotificationDropdownCtrl
        }
    })
    .name;
