export class Pagination {
    public current: number;
    public total: number;

    constructor(private loadCallback: () => Promise<void>, private itemsPerPage: number) {
        this.current = 1;
        this.total = 0;
    }

    async next() {
        if (this.current < this.last) {
            this.current++;
            await this.loadCallback();
        }
    }

    async prev() {
        if (this.current > 1) {
            this.current--;
            await this.loadCallback();
        }
    }

    get last() {
        return Math.ceil(this.total / this.itemsPerPage);
    }

    get offset() {
        return (this.current - 1) * this.itemsPerPage;
    }
}