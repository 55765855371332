import * as angular from "angular";
import {formatMoney} from "../../util/Money";

export default angular.module("inphinity.app.util", [])
    .filter('bytes', function () {
        return function (bytes, precision) {
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
            if (typeof precision === 'undefined') precision = 1;
            if (bytes == 0) precision = 0;
            var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB'];
            var unitIndex = (bytes == 0) ? 0 : Math.floor(Math.log(bytes) / Math.log(1024));
            return (bytes / Math.pow(1024, Math.floor(unitIndex))).toFixed(precision) + ' ' + units[unitIndex];
        }
    })
    .filter('money', function () {
        return formatMoney;
    })
    .name;

export {Worker} from "./Worker";
export {debounce} from "./debounce";