import {Worker} from "../util";
import TemplateService from "./TemplateService";
import {WorkerInterface} from "../util/Worker";

export default class TemplateDetailCtrl {
    static $resolve = {
        template: ($stateParams, TemplateService: TemplateService) => TemplateService.getTemplateInfo($stateParams.id),
        //@TODO move to TemplateService
        connectors: (ApiService) => ApiService.get("/api/templates").then(r => r.data)
    };

    public deployedAppId;
    public bundleInstall = {
        app: null,
        connectors: {},
    };
    public connectors;
    public auxConnectors;
    public scheduleCallUrl;
    public deployBundle: WorkerInterface;
    public demoLink;
    public r;
    public err;
    public link;
    public changelogOpen = false;

    constructor(public template, connectors, private AuthService, private $sce, private config, private TemplateService: TemplateService, private ApiService, private DialogService) {
        if (template.apps && template.apps[0]) {
            this.deployedAppId = template.apps[0].id;
        }

        this.bundleInstall.app = template;
        this.demoLink = this.config.qlik.demoUrl + "/sense/app/" + this.template.demoAppId;

        //@TODO filter on backend
        this.connectors = connectors.filter(conn => (conn.type == "Connector" && conn.dataModel == template.dataModel));
        this.auxConnectors = connectors.filter(conn => conn.type == "Connector" && template.auxDataModels.includes(conn.dataModel));
        this.deploy = Worker(this.deploy.bind(this));
        this.deployDemo = Worker(this.deployDemo.bind(this));
        this.deployBundle = Worker(this._deployBundle.bind(this));
        if (config.calendly) {
            this.scheduleCallUrl = config.calendly.url + "?a2=" + encodeURIComponent(template.name);
        }
    }

    formatNumber(i) {
        return Math.round(i);
    }

    canDeploy() {
        // @TODO move business rule to some service
        return !!this.AuthService.user;
    }

    deploy() {
        return this.TemplateService.deployTemplate(this.template.id, this.template.name);
    }

    async deployDemo() {
        this.deployedAppId = await this.TemplateService.deployDemoTemplate(this.template.id, this.template.name);
        this.template = await this.TemplateService.getTemplateInfo(this.template.id);
    }

    download() {
        this.ApiService.postForm("/api/templates/" + this.template.id + "/download/" + this.template.name);
    }

    private async _deployBundle() {
        // @TODO move process to backend
        if (this.bundleInstall.app) {
            this.deployedAppId = await this.TemplateService.deployTemplate(this.bundleInstall.app.id, this.bundleInstall.app.name);
            this.template = await this.TemplateService.getTemplateInfo(this.template.id);
        }
        let allConnectors = this.connectors.concat(this.auxConnectors);
        for (let tplId of Object.keys(this.bundleInstall.connectors).filter(key => this.bundleInstall.connectors[key])) {
            let connector = allConnectors.find(c => c.id == tplId);
            let connectorId = await this.TemplateService.deployTemplate(connector.id, connector.name);
            connector.apps.push({id: connectorId});
        }
    }

    open() {
        window.open(this.template.apps[0].url);
    }

    isApp() {
        return this.template.type == 'App';
    }

    getVideoUrl(url) {
        return this.$sce.trustAsResourceUrl(url);
    }

    getAlt(img) {
        let alt = img.substring(img.lastIndexOf("/"));
        alt = alt.substring(alt.indexOf("_") + 1, alt.length)
        alt = alt.substring(0, alt.lastIndexOf("."));
        alt = alt.replace(/\_/g, " ");
        return alt;
    }

    html(htmlString) {
        return this.$sce.trustAsHtml(htmlString);
    }

    getBundleTotal() {
        return (this.bundleInstall.app || {price: 0}).price
            + this.connectors.filter(c => this.bundleInstall.connectors[c.id]).map(c => c.price).reduce((a, b) => a + b, 0)
            + this.auxConnectors.filter(c => this.bundleInstall.connectors[c.id]).map(c => c.price).reduce((a, b) => a + b, 0)
    }

    isInactive() {
        if (this.deployedAppId === null) return true;
    }

    isDeployDisabled() {
        if (this.deployedAppId === null) return true;
        if (this.deployedAppId) return true;
        if (this.template.type == "Connector") return false;
        const availableMainConnectors = this.connectors;
        const installedMainConnectors = this.connectors.filter(c => c.apps && c.apps.length > 0);
        const selectedMainConnectors = this.connectors.filter(c => this.bundleInstall.connectors[c.id]);

        if (availableMainConnectors.length > 0 && installedMainConnectors.length == 0 && selectedMainConnectors.length == 0) return true;

        return false;
    }

    showSettingsInBundleSelector() {
        return this.deployedAppId && (this.connectors.length == 0 || this.template.type == "Connector");
    }

    showAppManagementInBundleConnector() {
        if (this.showSettingsInBundleSelector()) return false;
        return this.deployBundle.isDone() || (this.deployBundle.isPristine() && this.deployedAppId);
    }

    scheduleCall() {
        window["Calendly"].showPopupWidget(this.scheduleCallUrl);
    }

    async resetForms(id: string) {
        this.r = this.err = "";
        let result = await this.DialogService.confirm("Reset forms data to default?");
        if (result) {

            try {
                (await this.ApiService.post("/api/apps/" + id + "/resetForms", null)).data;
                this.r = "Forms data was reset."
            } catch (e) {
                this.err = "Unable to reset forms, please contact support."
            }
        }
    }

    share() {
        this.link = "https://" + window.location.hostname + "/share/store/" + this.template.id;
        navigator.clipboard.writeText(this.link);
    }

    changelog() {
        this.changelogOpen = !this.changelogOpen;
    }
}
