import * as angular from "angular";
import UsersCtrl from "./UsersCtrl";

export default angular.module("inphinity.app.admin.users", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("admin-users", {
                url: "/admin/users",
                template: require("./users.html").default,
                controllerAs: "ctrl",
                controller: UsersCtrl,
            })
    })
    .config(function routes($stateProvider) {
        $stateProvider
            .state("admin-users-list", {
                url: "/admin/userslist",
                template: require("./usersList.html").default,
                controllerAs: "ctrl",
                controller: UsersCtrl,
            })
    })
    .directive("paginationDirective", function () {
        return {
            restrict: 'E',
            scope: {
                pagination: '='
            },
            transclude: true,
            template: require("../Pagination.html").default,
        };
    })
    .name;
