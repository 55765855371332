import ApiService from "../../api/ApiService";
import {debounce, Worker} from "../../util";
import {FlowFeatures, FormsFeatures, FormsSaaSFeatures, MoleFeatures} from "../../../licenses/LicenseFeatures";
import {Pagination} from "../../admin/Pagination";
import DialogService from "../../dialogs/DialogService";

const ITEMS_PER_PAGE = 25;

const defaultKey = {
    prod: [],
    nbf: Math.floor(new Date().getTime() / 1000),
    exp: undefined,
    forms: {
        ins: -1,
        opt: FormsFeatures.ALL & ~(FormsFeatures.MULTINODE | FormsFeatures.HYBRID | FormsFeatures.QVD_ENCRYPTION),
        host: "*",
        capUsr: 0,
        planUsr: 0,
        planUsrConsumeBasicLic: 1
    },
    flow: {
        host: "*",
        opt: FlowFeatures.ALL & ~(FlowFeatures.WIZARD)
    },
    mole: {
        thr: 4,
        opt: MoleFeatures.ALL & ~(MoleFeatures.OCR | MoleFeatures.MAIL | MoleFeatures.CLASSIFIER | MoleFeatures.SSE)
    },
    saas: {
        opt: FormsSaaSFeatures.ALL,
        host: '',
        capUsr: 0,
        planUsr: 0,
        planUsrConsumeBasicLic: 1
    }
};

export default class LicensesCtrl {

    public result;
    public licenses = [];
    public search = {
        key: undefined
    };
    public hostname = window.location.host;

    public key = deepCopy(defaultKey);

    public lic = {
        partner: "",
        order: "",
        type: "",
        note: "",
        email: ""
    };

    public features = {
        forms: ["Multinode", "Form Comment Object", "SSE", "Collaboration", "Hybrid SaaS users", "XML", "SQL", "QVD", "Actions", "QVD encryption", "Modern UI"],
        flow: ["Actions", "Custom nodes", "Wizard"],
        mole: ["OCR", "Mail", "Classifier", "SSE"],
        saas: ["Database connections"]
    }

    public presets = [
        {
            name: "TRIAL (4W)",
            apply({lic, key}) {
                return {
                    lic: {
                        ...lic,
                        type: "trial",
                    },
                    key: {
                        sub: key?.sub,
                        prod: ["inphinity-forms", "inphinity-flow", "inphinity-mole"],
                        nbf: key?.nbf,
                        exp: Math.round(new Date().getTime() / 1000 + 4 * 7 * 24 * 3600),
                        forms: {
                            cid: key?.forms?.cid,
                            usr: -1,
                            capUsr: 0,
                            planUsr: -1,
                            ins: -1,
                            opt: defaultKey.forms.opt,
                            host: key?.forms?.host || defaultKey.forms.host,
                            planUsrConsumeBasicLic: 1
                        },
                        flow: {
                            host: "*",
                            opt: defaultKey.flow.opt | FlowFeatures.WIZARD
                        },
                        mole: {
                            cid: key?.mole?.cid,
                            thr: -1,
                            opt: MoleFeatures.ALL
                        },
                        saas: {
                            opt: defaultKey.saas.opt,
                            usr: 10,
                            capUsr: 0,
                            planUsr: 10,
                            ins: -1,
                            planUsrConsumeBasicLic: 1
                        }
                    }
                }
            }
        },
        {
            name: "NFR SUITE (1Y)",
            apply({lic, key}) {
                let exp = new Date();
                exp.setFullYear(exp.getFullYear() + 1);
                return {
                    lic: {
                        ...lic,
                        type: "NFR",
                    },
                    key: {
                        sub: key?.sub,
                        prod: ["inphinity-forms", "inphinity-flow", "inphinity-mole"],
                        nbf: key?.nbf,
                        exp: Math.round(exp.getTime() / 1000),
                        forms: {
                            cid: key?.forms?.cid,
                            ins: -1,
                            usr: 100,
                            capUsr: 0,
                            planUsr: 100,
                            opt: FormsFeatures.ALL,
                            host: key?.forms?.host || defaultKey.forms.host,
                            planUsrConsumeBasicLic: 1
                        },
                        flow: {
                            host: "*",
                            opt: FlowFeatures.ALL
                        },
                        mole: {
                            cid: key?.mole?.cid,
                            thr: -1,
                            opt: MoleFeatures.ALL
                        },
                        saas: {
                            ins: -1,
                            opt: FormsSaaSFeatures.ALL,
                            host: key?.saas?.host || defaultKey.saas.host,
                            planUsrConsumeBasicLic: 1
                        }
                    }
                }
            },
        },
        {
            name: "FULL SUITE (1Y)",
            apply({lic, key}) {
                let exp = new Date();
                exp.setFullYear(exp.getFullYear() + 1);
                return {
                    lic: {
                        ...lic,
                        type: "full",
                    },
                    key: {
                        sub: key?.sub,
                        prod: ["inphinity-forms", "inphinity-flow", "inphinity-mole"],
                        nbf: key?.nbf,
                        exp: Math.round(exp.getTime() / 1000),
                        forms: {
                            cid: key?.forms?.cid,
                            ins: -1,
                            opt: defaultKey.forms.opt,
                            host: key?.forms?.host || defaultKey.forms.host,
                            planUsrConsumeBasicLic: 1
                        },
                        flow: {
                            host: "*",
                            opt: defaultKey.flow.opt
                        },
                        mole: {
                            cid: key?.mole?.cid,
                            thr: 4,
                            opt: defaultKey.mole.opt
                        },
                        saas: {
                            ins: -1,
                            opt: defaultKey.saas.opt,
                            host: key?.saas?.host || defaultKey.saas.host,
                            planUsrConsumeBasicLic: 1
                        }
                    }
                }
            },
        },
        {
            name: "Trial Saas (4W)",
            apply({lic, key}) {
                let exp = new Date();
                exp.setFullYear(exp.getFullYear() + 1);
                return {
                    lic: {
                        ...lic,
                        type: "trial",
                    },
                    key: {
                        sub: key?.sub,
                        prod: ["inphinity-forms-saas", "inphinity-flow"],
                        nbf: key?.nbf,
                        exp: Math.round(new Date().getTime() / 1000 + 4 * 7 * 24 * 3600),
                        flow: {
                            host: "*",
                            opt: defaultKey.flow.opt | FlowFeatures.WIZARD
                        },
                        saas: {
                            ins: -1,
                            opt: defaultKey.saas.opt,
                            usr: 10,
                            capUsr: 0,
                            planUsr: 10,
                            host: key?.saas?.host || defaultKey.saas.host,
                            planUsrConsumeBasicLic: 1
                        }
                    }
                }
            }
        },
        {
            name: "NFR Saas (1Y)",
            apply({lic, key}) {
                let exp = new Date();
                exp.setFullYear(exp.getFullYear() + 1);
                return {
                    lic: {
                        ...lic,
                        type: "NFR",
                    },
                    key: {
                        sub: key?.sub,
                        prod: ["inphinity-forms-saas", "inphinity-flow"],
                        nbf: key?.nbf,
                        exp: Math.round(exp.getTime() / 1000),
                        flow: {
                            host: "*",
                            opt: FlowFeatures.ALL
                        },
                        saas: {
                            ins: -1,
                            usr: 30,
                            capUsr: 0,
                            planUsr: 30,
                            opt: defaultKey.saas.opt,
                            host: key?.saas?.host || defaultKey.saas.host,
                            planUsrConsumeBasicLic: 1
                        }
                    }
                }
            }
        },
        {
            name: "FULL Saas (1Y)",
            apply({lic, key}) {
                let exp = new Date();
                exp.setFullYear(exp.getFullYear() + 1);
                return {
                    lic: {
                        ...lic,
                        type: "full",
                    },
                    key: {
                        sub: key?.sub,
                        prod: ["inphinity-forms-saas", "inphinity-flow"],
                        nbf: key?.nbf,
                        exp: Math.round(exp.getTime() / 1000),
                        flow: {
                            host: "*",
                            opt: defaultKey.flow.opt
                        },
                        saas: {
                            ins: -1,
                            opt: defaultKey.saas.opt,
                            host: key?.saas?.host || defaultKey.saas.host,
                            planUsrConsumeBasicLic: 1
                        }
                    }
                }
            }
        }
    ]

    public pagination: Pagination;

    constructor(public SettingsService, protected ApiService: ApiService, private DialogService: DialogService, $state) {
        this.generate = Worker(this.generate.bind(this));
        this.load = Worker(this.load.bind(this));
        this.searchChanged = debounce(250, this.searchChanged);
        this.pagination = new Pagination(this.load.bind(this), ITEMS_PER_PAGE);
        void this.load();

        if ($state.params.computerId || $state.params.company) {
            this.key.forms.cid = +$state.params.computerId;
            this.key.mole.cid = +$state.params.computerId;
            this.key.sub = $state.params.company;

            this.lic.email = $state.params.email;
            if ($state.params.note != undefined) {
                this.lic.note = $state.params.note;
            }
            this.applyPreset(this.presets[0]);

            if ($state.params.product == "inphinity-flow") {
                this.key.prod = ["inphinity-flow"];
            }
        }
    }

    isProductEnabled(key, prod) {
        return key.prod === prod || (key.prod.indexOf && key.prod.indexOf(prod) >= 0);
    }

    isProductOnlySaaS(key) {
        return ((key.prod == "inphinity-forms-saas" && key.prod.length == 1) || ((key.prod.includes("inphinity-flow")) && (key.prod.includes("inphinity-forms-saas")) && key.prod.length == 2));
    }

    inputToArray(input) {
        if (Array.isArray(input)) {
            return input;
        }
        return [input];
    }

    getAllComputerIds(key) {
        let ids = []
        if (this.isProductEnabled(key, 'inphinity-forms')) {
            ids = ids.concat(this.inputToArray(key.forms.cid))
        }
        if (this.isProductEnabled(key, 'inphinity-mole')) {
            ids = ids.concat(this.inputToArray(key.mole.cid))
        }
        ids = ids.filter((item) => item !== "*");
        return Array.from(new Set(ids)).join(", ");
    }

    getAllHostnames(key) {
        let hosts = []
        if (this.isProductEnabled(key, 'inphinity-forms')) {
            hosts = hosts.concat(this.inputToArray(key.forms.host))
        }
        if (this.isProductEnabled(key, 'inphinity-flow')) {
            hosts = hosts.concat(this.inputToArray(key.flow.host))
        }
        if (this.isProductEnabled(key, 'inphinity-forms-saas')) {
            hosts = hosts.concat(this.inputToArray(key.saas.host))
        }
        hosts = hosts.filter((item) => item !== "*");
        return Array.from(new Set(hosts)).join(", ");
    }

    applyPreset(preset) {
        let {lic, key} = preset.apply({lic: this.lic, key: this.key});
        this.lic = lic;
        this.key = key;
    }

    async generate() {
        this.result = (await this.ApiService.post("/api/licenses/suite/generate", {lic: this.lic, key: this.key})).data;
        void this.load();
    }

    searchChanged() {
        this.pagination.current = 1;
        return this.load();
    }

    async load() {
        let {data} = await this.ApiService.get("/api/licenses/suite/list?offset=" + this.pagination.offset + "&search=" + encodeURIComponent(JSON.stringify(this.search)));
        this.pagination.total = data.totalCount;
        this.licenses = data.results;
    }

    async save(lic) {
        await this.ApiService.post("/api/licenses/suite/" + lic.lic.id, {sub: lic.key.sub, ...lic.lic});
        void this.load();
    }

    async edit(lic) {
        lic.edit = true;
    }

    copyKey(lic) {
        let input = document.createElement("input");
        document.body.appendChild(input);
        input.value = lic.jwt;
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
    }

    copyFlowLicenseLink(lic) {
        console.log(lic)
        let input = document.createElement("input");
        document.body.appendChild(input);
        input.value = this.hostname + '/api/licenses/inphinity-flow-license.zip?jti=' + lic.key.jti;
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
    }

    extendLicense(lic) {
        this.lic = {
            partner: lic.lic.partner,
            order: lic.lic.order,
            type: lic.lic.type,
            note: lic.lic.note,
            email: lic.lic.email
        }

        this.key = deepCopy(lic.key);
        this.key.forms = this.key.forms || deepCopy(defaultKey.forms);
        this.key.flow = this.key.flow || deepCopy(defaultKey.flow);
        this.key.mole = this.key.mole || deepCopy(defaultKey.mole);
        this.key.saas = this.key.saas || deepCopy(defaultKey.saas);
        this.key.exp = undefined;
        this.key.nbf = Math.floor(new Date().valueOf() / 1000);
        if (!this.key.forms.planUsr) this.key.forms.planUsr = 0;
        if (this.key.forms.planUsrConsumeBasicLic == undefined) this.key.forms.planUsrConsumeBasicLic = true;
        if (!this.key.saas.planUsr) this.key.saas.planUsr = 0;
        if (this.key.saas.planUsrConsumeBasicLic == undefined) this.key.saas.planUsrConsumeBasicLic = true;
        window.scrollTo(0, 0);
    }

    getProductList(key) {
        const names = {
            "inphinity-forms": "Forms",
            "inphinity-flow": "Flow",
            "inphinity-mole": "Mole",
            "inphinity-forms-saas": "Forms-Saas",
        };
        if (typeof key.prod === "string") return "Inphinity " + names[key.prod];
        if (key.prod.length === 1) return "Inphinity " + names[key.prod[0]];
        if (key.prod.length === Object.keys(names).length) return "Inphinity Suite";
        return "Inphinity " + key.prod.slice(0, key.prod.length - 1).map(p => names[p]).join(", ") + " and " + names[key.prod[key.prod.length - 1]];
    }

    getSearch() {
        cleanEmpty(this.search);
        return this.search;
    }

    getLicenseDetails(l) {
        return "License"
            + "\nStart date: " + l.lic.nbf
            + "\nEnd date: " + l.lic.exp
            + "\nType: " + l.lic.type
            + "\nCompany: " + l.key.sub
            + "\nOrder no: " + l.lic.order
            + "\nNote: " + l.lic.note

            + (this.isProductEnabled(l.key, "inphinity-forms") ? (
                "\n\nForms"
                + "\ndomain: " + l.key.forms?.host.join(',')
                + "\ncid: " + l.key.forms?.cid.join(',')
                + "\nusers: " + (l.key.forms?.usr == -1 ? "unlimited" : l.key.forms?.usr)
                + "\ncapacity users: " + l.key.forms?.capUsr
                + "\nplanning users: " + (l.key.forms?.planUsr == -1 ? "unlimited" : l.key.forms?.planUsr)
                + "\nforms: " + (l.key.forms?.ins == -1 ? "unlimited" : l.key.forms?.ins)
                + "\nfeatures: " + dec2bin(l.key.forms?.opt)
                + "\nplanning user consume basic license: " + l.key.forms?.pucbs
            ) : '')
            + (this.isProductEnabled(l.key, 'inphinity-flow') ? (
                "\n\nFlow"
                + "\ndomain: " + l.key.flow?.host.join(',')
                + "\nfeatures: " + dec2bin(l.key.flow?.opt)
            ) : '')
            + (this.isProductEnabled(l.key, 'inphinity-forms-saas') ? (
                "\n\nForms-Saas"
                + "\ndomain: " + l.key.saas?.host.join(',')
                + "\nusers: " + (l.key.saas?.usr == -1 ? "unlimited" : l.key.saas?.usr)
                + "\ncapacity users: " + l.key.saas?.capUsr
                + "\nplanning users: " + (l.key.saas?.planUsr == -1 ? "unlimited" : l.key.saas?.planUsr)
                + "\nforms: " + (l.key.saas?.ins == -1 ? "unlimited" : l.key.saas?.ins)
                + "\nfeatures: " + dec2bin(l.key.saas?.opt)
                + "\nplanning user consume basic license: " + l.key.saas?.pucbs
            ) : '')
            + (this.isProductEnabled(l.key, 'inphinity-mole') ? (
                "\n\nMole"
                + "\ncid: " + l.key.mole?.cid.join(',')
                + "\nthreads: " + (l.key.mole?.thr == -1 ? "unlimited" : l.key.mole?.thr)
                + "\nfeatures: " + dec2bin(l.key.mole?.opt)
            ) : '')
    }

    validateNumberOfPlanningUsers() {
        return this.key.forms.planUsrConsumeBasicLic &&
            (this.key.forms.usr != -1 && this.key.forms.planUsr != -1 && this.key.forms.planUsr > this.key.forms.usr);
    }

    validateNumberOfPlanningSaasUsers() {
        return this.key.saas.planUsrConsumeBasicLic &&
            (this.key.saas.usr != -1 && this.key.saas.planUsr != -1 && this.key.saas.planUsr > this.key.saas.usr);
    }

    async applySaasLicence(lic) {
        let result = await this.DialogService.confirm("Re-apply Forms SaaS license for " + lic.key.saas.host + "? Actual license data will be replaced.");
        if (result) {
            await this.ApiService.post("/api/licenses/suite/" + lic.lic.id + "/reapply", {});
        }
    }

}

function deepCopy(x) {
    return JSON.parse(JSON.stringify(x));
}

function cleanEmpty(obj) {
    for (let k in obj) {
        if (obj[k] === "") {
            delete obj[k];
        } else if (typeof obj[k] === "object") {
            cleanEmpty(obj[k]);
            if (Object.keys(obj[k]).length === 0) delete obj[k];
        }
    }
}

function dec2bin(dec) {
    return (dec >>> 0).toString(2);
}
