import BillingService from "./BillingService";
import {Worker} from "../util";
import * as countries from "../util/countries";

export default class BillingCtrl {

    static $resolve = {
        defaultInvoice: (BillingService: BillingService) => BillingService.getDefaultPaymentDetails(),
        lastTransactions: (BillingService: BillingService) => BillingService.getLastTransactions(),
        proFormaList: (BillingService: BillingService) => BillingService.getTenantProForma()
    }

    public chargeStep = 0;
    public paymentType = null;
    public countries = countries;
    public invoice: any = {
        VATregistered: false
    };
    public invoiceLink;
    public acceptTOS;

    public lastTransactions;
    public proFormaList;

    constructor(private BillingService: BillingService, defaultInvoice, lastTransactions, proFormaList, private config) {
        this.generateProForma = Worker(this.generateProForma.bind(this));
        this.invoice = {
            ...this.invoice,
            ...defaultInvoice
        };
        this.chargeStep = 0;
        this.paymentType = "transfer";

        this.lastTransactions = lastTransactions;
        this.proFormaList = proFormaList;

        this.invoice.amount = 1000;
        this.invoice.currency = this.config.currency;
    }

    validateBankTransferDetails() {
        if (!this.invoice.amount) return false;
        if (!this.invoice.companyName) return false;
        if (!this.invoice.address) return false;
        if (!this.invoice.city) return false;
        if (!this.invoice.email) return false;
        if (!this.invoice.country) return false;
        if (!this.invoice.companyID) return false;
        if (!this.invoice.companyTAX) return false;
        if (this.invoice.VATregistered && !this.invoice.companyVAT) return false;

        if (this.invoice.amount < 1000 && this.lastTransactions.length == 0) return false;
        if (this.invoice.amount < 200 && this.lastTransactions.length > 0) return false;

        return true;
    }

    validateConfirmation() {
        return this.acceptTOS;
    }

    async generateProForma() {
        if (!this.invoice.VATregistered) this.invoice.companyVAT = "";
        const inv = await this.BillingService.generateProForma(this.invoice);
        this.invoiceLink = "/api/billing/proforma/" + inv.id + "/" + inv.permalink;
        window.open(this.invoiceLink, "_blank");
    }

    showProForma(proForma) {
        var invoiceLink = "/api/billing/proforma/" + proForma.id + "/" + proForma.permalink;
        window.open(invoiceLink, "_blank");
    }

}
