export default class DialogService {
    public constructor(private SweetAlert, private $q) {
    }

    confirm(text): Promise<boolean> {
        const defer = this.$q.defer();
        this.SweetAlert.swal(
            {
                title: "Are you sure?",
                text: text,
                type: "warning",
                showCancelButton: true,
                cancelButtonText: "No",
                closeOnConfirm: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes"
            },
            (result) => defer.resolve(result)
        );
        return defer.promise;
    }

    error(text): Promise<boolean> {
        const defer = this.$q.defer();
        this.SweetAlert.swal(
            {
                title: "Error",
                text: text,
                type: "error",
                showCancelButton: false,
                closeOnConfirm: true,
            },
            (result) => defer.resolve(result)
        );
        return defer.promise;
    }

    warning(text): Promise<boolean> {
        const defer = this.$q.defer();
        this.SweetAlert.swal(
            {
                title: "Warning",
                text: text,
                type: "warning",
                showCancelButton: false,
                closeOnConfirm: true,
            },
            (result) => defer.resolve(result)
        );
        return defer.promise;
    }

    success(text): Promise<boolean> {
        const defer = this.$q.defer();
        this.SweetAlert.swal(
            {
                title: "Success",
                text: text,
                type: "success",
                showCancelButton: false,
                closeOnConfirm: true,
            },
            (result) => defer.resolve(result)
        );
        return defer.promise;
    }

    custom(options): Promise<any> {
        const defer = this.$q.defer();
        this.SweetAlert.swal(options, (result) => defer.resolve(result));
        return defer.promise;
    }
    
    share(link) {
        this.SweetAlert.swal({
            title: "Link copied",
            text: link,
            type: "success",
            confirmButtonColor: "#60bb46",
            showCancelButton: false,
            closeOnConfirm: true,
            customClass: 'swal-wide',
        }, () => {});
    }

    loading(text): Promise<boolean> {
        const defer = this.$q.defer();
        this.SweetAlert.swal(
            {
                title: '<i class="icon-spinner3 spinner" style="font-size: 40px"></i>',
                type: "",
                text: text,
                showCancelButton: false,
                showConfirmButton: false,
                closeOnConfirm: false,
                html: true,
            },
            (result) => defer.resolve(result)
        );
        return defer.promise;
    }
}
