import {Worker} from "../util";
import UnifiedAuthService from "../auth/UnifiedAuthService";
import {LoginInfo} from "../../login/types";
export default class LoginCtrl {

    public static $resolve = {

    }

    public username = "";
    public password = "";
    public loginOptions: LoginInfo[] | undefined;

    constructor(private AuthService: UnifiedAuthService) {
        this.login = Worker(this.login.bind(this));
    }

    async login(){
        const data = await this.AuthService.requestLogin(this.username, this.password);
        if(data.length > 1){
            this.loginOptions = data;
        }
    }

    async selectLogin(option: LoginInfo){
        this.loginOptions = undefined;
        await this.AuthService.processLogin(option);
    }
}