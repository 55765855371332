import {ApiServiceInterface} from "../api/ApiService";
import {UserListItem, UserProfile} from "../../user";

export default class UserService {
    constructor(private ApiService: ApiServiceInterface) {
    }

    async listUsers(): Promise<UserListItem[]> {
        return (await this.ApiService.get("/api/user/list")).data;
    }

    async getUser(id: string): Promise<UserProfile> {
        return (await this.ApiService.get("/api/user/" + id)).data;
    }

    async deleteUser(id: string) {
        return (await this.ApiService.delete("/api/user/" + id)).data;
    }

    async enableUser(id: string) {
        return (await this.ApiService.post("/api/user/" + id + "/enable", null)).data;
    }

    async resetPassword(id: string) {
        return (await this.ApiService.post("/api/user/" + id + "/resetPassword", null)).data;
    }

    async listTenantQlikGroups(): Promise<any[]> {
        return (await this.ApiService.get("/api/user/tenant/qlikGroups")).data;
    }

    async enablePlanningUser(id: string) {
        return (await this.ApiService.post("/api/user/" + id + "/planning", null)).data;
    }

    async disablePlanningUser(id: string) {
        return (await this.ApiService.delete("/api/user/" + id + "/planning")).data;
    }

}
