export default class SettingsService {
    private profileCache;
    private Admin = 0;
    private FormsAdmin = 0;
    private Owner = 0;
    private SiteAdmin = 0;
    private Partner = 0;
    public Tenant;

    constructor(private ApiService) {
    }

    saveUserProfile(data) {
        this.profileCache = null;
        return this.ApiService.post("/api/user/" + data.id, data);
    }

    saveTenantProfile(data) {
        this.profileCache = null;
        return this.ApiService.post("/api/user/me/tenant", data);
    }

    inviteUser(data) {
        return this.ApiService.post("/api/user/invite", data);
    }

    setUserPassword(id, password) {
        return this.ApiService.post("/api/user/" + id + "/password", {password}).then(r => r.data);
    }

    getProfile() {
        return this.profileCache = this.ApiService.get("/api/user/me/profile").then(r => {
            this.Admin = r.data.isAdmin;
            this.Owner = r.data.isOwner;
            this.FormsAdmin = r.data.isFormsAdmin;
            this.SiteAdmin = r.data.isSiteAdmin;
            this.Partner = r.data.isPartner;
            this.Tenant = r.data.tenant;

            return r.data
        }).then(null, err => {
            this.Admin = 0;
            this.FormsAdmin = 0;
            this.Owner = 0;
            this.SiteAdmin = 0;
            this.Partner = 0;

            if (err && err.data && err.data.class == "UserProfileNotExistsError") return null;
            throw err;
        });
    }

    getCachedProfile() {
        return this.profileCache || this.getProfile();
    }

    async updatePermissions(permList: any[]) {
        await this.ApiService.post("/api/user/permissions", permList);
    }

    async updateFormsPermissions(permList: any[]) {
        await this.ApiService.post("/api/user/formsPermissions", permList);
    }

    async updateFavourites(appList: any[]) {
        await this.ApiService.post("/api/user/favourite", appList);
    }

    async switchTenant(tenantId) {
        await this.ApiService.post("/api/user/me/tenants/" + tenantId + "/switch");
        await this.getProfile();
    }

    isAdmin() {
        return this.Admin;
    }

    isAdminOrFormsAdmin() {
        return this.FormsAdmin || this.Admin;
    }

    isFormsAdmin() {
        return this.FormsAdmin;
    }

    isOwner() {
        return this.Owner;
    }

    isSiteAdmin() {
        return this.SiteAdmin;
    }

    isPartner() {
        return this.Partner;
    }

    isFormsActive() {
        if (this.Tenant === undefined || this.Tenant === null) return false;
        return this.Tenant.formsActive;
    }

    async getUserToken() {
        return (await this.ApiService.get("/api/user/me/token")).data;
    }
}
