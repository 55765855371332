export function mssqlEscape(value: string) {
    const MSSQL_SPECIAL_CHARS = "{}[]; ";
    if (MSSQL_SPECIAL_CHARS.split("").some(char => value.includes(char))) {
        return "{" + value.split("}").join("{}}") + "}";
    }
    return value;
}

export function isValidHost(value: string) {
    return /^([a-zA-Z0-9-.]+|\[[0-9a-fA-F:]+])(:[0-9]+)?$/.test(value);
}

export function validateHost(value: string) {
    if (!isValidHost(value)) {
        throw new Error("Invalid host: " + value);
    }
    return value;
}