export default class AppService {
    constructor(private ApiService) {
    }

    async getApps() {
        return (await this.ApiService.get("/api/apps")).data;
    }

    async getApp(id) {
        return (await this.ApiService.get("/api/apps/" + id)).data;
    }

    async getAllApps() {
        return (await this.ApiService.get("/api/admin/apps")).data;
    }

    deleteApp(id: string, tenantId) {
        return this.ApiService.post("/api/apps/" + id + "/delete", tenantId);
    }

    upgradeApp(id: string) {
        return this.ApiService.post("/api/apps/" + id + "/upgrade");
    }

    reloadApp(id: string) {
        return this.ApiService.post("/api/apps/" + id + "/reload");
    }

    async deleteDataModel(id: string) {
        return (await this.ApiService.delete("/api/apps/models/" + id)).data;
    }

    async getAppPermissions(id: string) {
        return (await this.ApiService.get("/api/apps/" + id + "/permissions")).data;
    }

    getReloadStatusInfo(status) {
        const statusMap = {
            0: {text: "Never started", class: "info"},
            1: {text: "Triggered", class: "info", busy: true},
            2: {text: "Started", class: "info", busy: true},
            3: {text: "Queued", class: "info", busy: true},
            4: {text: "Abort initiated", class: "warning", busy: true},
            5: {text: "Aborting", class: "warning", busy: true},
            6: {text: "Aborted", class: "warning"},
            7: {text: "Success", class: "success"},
            8: {text: "Failed", class: "danger"},
            9: {text: "Skipped", class: "warning"},
            10: {text: "Retrying", class: "warning", busy: true},
            11: {text: "Error", class: "danger"},
            12: {text: "Reset", class: "info"},
        }
        if (statusMap[status]) return statusMap[status];
        return {text: "Unknown", class: "danger"};
    }

    shouldShowReloadTime(app) {
        return app.reload.status > 0 && new Date(app.lastReloadTime) > new Date(app.createdDate);
    }
}
