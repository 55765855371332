import * as angular from "angular";

export default angular.module("inphinity.app.static", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("home", {
                url: "/home",
                templateUrl: "html/home.html",
                onEnter(config) {
                    if (config.home && config.home != "/home") {
                        window.location.hash = "#!" + config.home;
                    }
                }
            })
            .state("partners", {url: "/partners", templateUrl: "html/partners.html"})
            .state("inphinity-team", {url: "/inphinity-team", templateUrl: "html/inphinity-team.html"})
            .state("about", {url: "/about", templateUrl: "html/about.html"})
            .state("security-guarantee", {url: "/security-guarantee", templateUrl: "html/security-guarantee.html"})
            .state("terms-and-conditions", {
                url: "/terms-and-conditions",
                templateUrl: "html/terms-and-conditions.html"
            })
            .state("privacy-policy", {url: "/privacy-policy", templateUrl: "html/privacy-policy.html"})
            .state("hall-of-fame", {url: "/hall-of-fame", templateUrl: "html/hall-of-fame.html"})
            .state("contact-us", {url: "/contact-us", templateUrl: "html/contact-us.html"})
            .state("internal-login", {
                url: "/internal-login",
                template: '<button ng-click="AuthService.login()">Log in</button>',
                controller: function (AuthService, $scope) {
                    $scope.AuthService = AuthService
                }
            })
            .state("documentation", {url: "/documentation", templateUrl: "html/documentation.html"})
    })
    .name;
