import {Worker} from "../util";
import AppManagementCtrlBase from "./AppManagementCtrlBase"

export default class AppManagementCtrl extends AppManagementCtrlBase {
    public constructor(config, DialogService, AppService, $timeout, ApiService) {
        super(config, DialogService, AppService, $timeout, ApiService);
        this.reloadAppList = Worker(this.reloadAppList.bind(this));
        this.reloadAppList();
    }
}
