import * as angular from "angular";
import auth0 from "auth0-js";
import UnifiedAuthService from "./UnifiedAuthService";
import AuthService from "./AuthService";

window["auth0"] = auth0;
const config = window["inphinity-config"];

export default angular.module("inphinity.app.auth", [])
    .service("AuthService", config.useUnifiedLogin ? UnifiedAuthService : AuthService)
    .run(function (AuthService) {
        AuthService.run();
    })
    .name;
