import SubscriptionService from "./SubscriptionService";
import {Worker} from "../util";
import DialogService from "../dialogs/DialogService";
import * as moment from "moment";
import {SubscriptionInfo} from "../../billing";

export default class SubscriptionCtrl {

    public subscriptions: SubscriptionInfo[];
    public newUserCounts;
    public subscriptionMap;
    public monthlyTotal;
    public orders = [];
    public activationTotal;
    public relativeMonthlyTotal;
    public billingPeriodName;

    static $resolve = {
        subscriptions: (SubscriptionService: SubscriptionService) => SubscriptionService.getMySubscriptions(),
        account: (SubscriptionService: SubscriptionService) => SubscriptionService.getMyAccount()
    }

    constructor(subscriptions, $scope, private SubscriptionService: SubscriptionService, public account, private DialogService: DialogService, private config) {
        $scope.$watchCollection("ctrl.newUserCounts", this.recalculate.bind(this));
        this.confirmOrder = Worker(this.confirmOrder.bind(this));
        this.reset = Worker(this.reset.bind(this));
        this.show(subscriptions);
        this.billingPeriodName = moment().format("MMMM YYYY");
    }

    reset() {
        this.show(this.subscriptions);
    }

    show(subscriptions) {
        this.subscriptions = subscriptions;
        this.subscriptionMap = subscriptions.reduce((sm, sub) => {
            sm[sub.id] = sub;
            return sm;
        }, {});

        this.newUserCounts = subscriptions.filter(sub => sub.active && !sub.trial).reduce((nc, sub) => {
            nc[sub.id] = sub.userCount;
            return nc;
        }, {});
    }

    recalculate() {
        this.orders = [];

        this.subscriptions.forEach(sub => {
            if (this.newUserCounts[sub.id] !== undefined) {
                this.newUserCounts[sub.id] = parseInt(this.newUserCounts[sub.id], 10);
                let oldUserCount = sub.userCount;
                if (sub.trial) oldUserCount = 0;
                let minCount = sub.permissionCount
                if (sub.trial) minCount = 1;
                if (this.newUserCounts[sub.id] < minCount || isNaN(this.newUserCounts[sub.id])) {
                    this.newUserCounts[sub.id] = minCount;
                }
                let order = {
                    id: sub.id,
                    userCount: this.newUserCounts[sub.id],
                    relativeUserCount: this.newUserCounts[sub.id] - oldUserCount,
                    activationPrice: 0,
                    relativeMonthPrice: 0,
                };
                order.relativeMonthPrice = order.relativeUserCount * sub.template.price;
                if (order.relativeUserCount > 0) {
                    order.activationPrice = this.account.billingPeriodFactor * sub.template.price * order.relativeUserCount
                }
                if (order.relativeUserCount != 0) {
                    this.orders.push(order);
                }
            }
        });


        this.monthlyTotal = this.subscriptions.reduce((sum, sub) => sum += (this.newUserCounts[sub.id] || 0) * (sub.template.price || 0), 0);
        this.activationTotal = this.orders.reduce((sum, ord) => sum += ord.activationPrice, 0);
        this.relativeMonthlyTotal = this.orders.reduce((sum, ord) => sum += ord.relativeMonthPrice, 0);
    }

    async convertTrial(sub) {
        if (await this.DialogService.confirm(
            "You are about to end your trial and start paid subscription.\n"
            + "By terminating the trial, you will lose any changes in the application (sheets, bookmarks, etc.).\n"
            + "Your settings and uploaded files will be preserved."
        )) {
            this.newUserCounts[sub.id] = 1;
            this.recalculate();
        }
    }

    startSubscription(sub) {
        this.newUserCounts[sub.id] = 1;
        this.recalculate();
    }

    async confirmOrder() {
        await this.SubscriptionService.confirmOrder(this.orders);
        this.show(await this.SubscriptionService.getMySubscriptions());
        this.account = await this.SubscriptionService.getMyAccount();
        this.recalculate();
    }

    daysRemaining(sub) {
        if (sub.active && sub.deactivationTime) {
            return -moment().diff(moment(sub.deactivationTime).endOf("day"), "days");
        }
        return 0;
    }
}
