import DialogService from "../dialogs/DialogService";

let loginHash = null;
let loginErrorHash = null;
if (window.location.hash.includes("access_token")) {
    loginHash = window.location.hash;
}
if (window.location.hash.includes("access_denied") || window.location.hash.includes("error=unauthorized")) {
    loginErrorHash = window.location.hash;
}

/**
 * This class provides access to
 *  - access token - for API calls
 *  - user - identity provide supplied info
 **/

export default class AuthService {
    private provider;
    public expiresAt;
    private accessToken;
    private user;
    private defferedLogin;

    constructor(angularAuth0, private $state, private $timeout, private $rootScope, $q, private DialogService: DialogService) {
        this.provider = angularAuth0;
        this.defferedLogin = $q.defer();
    }

    run() {
        if (loginErrorHash) {
            if (loginErrorHash.includes("user%20is%20blocked")) {
                this.DialogService.error("User account is disabled");
            }
            //@TODO handle login error
        }
        if (!loginHash) {
            void this.renewTokens();
        } else {
            this.handleAuthentication(loginHash);
        }
    }

    handleAuthentication(hash) {
        this.provider.parseHash({hash: hash}, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.localLogin(authResult);
            } else if (err) {
                this.defferedLogin.resolve(false);
            }
        });
    }

    // receive auth token from login
    localLogin(authResult) {
        this.expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
        this.accessToken = authResult.accessToken;
        try {
            sessionStorage.setItem("inphinity_token", this.accessToken);
            sessionStorage.setItem("inphinity_token_exp", this.expiresAt);
        } catch (e) {
            console.warn("Failed to store token to session storage")
        }
        if(authResult?.appState?.locationHash){
            window.location.hash = authResult.appState.locationHash
        }
        this.provider.client.userInfo(this.accessToken, (err, user) => {
            this.user = user;
            this.defferedLogin.resolve(this.user);
            this.$rootScope.$digest();
        });
    }

    // load auth token from session storage
    async loadSession(): Promise<boolean> {
        try {
            let token = sessionStorage.getItem("inphinity_token");
            let exp = sessionStorage.getItem("inphinity_token_exp");
            if (!token) return false;
            return new Promise<boolean>((resolve, reject) => {
                this.provider.client.userInfo(token, (err, user) => {
                    if (user && !err) {
                        console.log("loaded session from session storage")
                        this.user = user;
                        this.accessToken = token;
                        this.expiresAt = +exp;
                        this.defferedLogin.resolve(this.user);
                        this.$rootScope.$digest();
                        resolve(true);
                    } else {
                        console.log("session invalid");
                        resolve(false);
                    }
                })
            })
        } catch (e) {
            console.warn("failed to load token from session storage");
        }
        return false;
    }

    // resume session after reload (from session storage or by silent login)
    async renewTokens() {
        if (await this.loadSession()) return;
        console.log("token expired, renew login");
        this.provider.checkSession({},
            async (err, result) => {
                if (err) {
                    console.log("login error", err);
                    if (err.error == "access_denied" && err.error_description == "Signup disabled") {
                        await this.DialogService.warning("Registration of new user accounts opens in June 2019.");
                        this.logout();
                    }
                    this.defferedLogin.resolve(false);
                } else {
                    this.localLogin(result);
                }
            }
        );
    }

    logout() {
        this.accessToken = '';
        this.expiresAt = 0;
        this.user = null;
        try {
            sessionStorage.removeItem("inphinity_token");
            sessionStorage.removeItem("inphinity_token_exp");
        } catch (e) {

        }
        this.provider.logout({returnTo: window.location.protocol + "//" + window.location.host});
    }

    isLoggedIn() {
        return !!this.user;
    }

    login(targetLocationHash?: string) {
        this.provider.authorize({
            appState: {
                locationHash: targetLocationHash || window.location.hash
            }
        });
    }

    // returns a promise that resolves to user account (identity info) when available
    // if user is not logged in, promise rejects
    waitLogin() {
        return this.defferedLogin.promise;
    }

    // returns user identity info if exists
    getUser() {
        return this.user;
    }

    getAccessToken() {
        return this.accessToken;
    }
}
