export default () => ({
    template: `
        <div ng-repeat="flag in ctrl.flags">
            <label>
                <input type="checkbox" ng-model="ctrl.checkbox[$index]" ng-change="ctrl.update()" ng-disabled="ctrl.disabled">
                {{flag}}
            </label>
        </div>
        <div ng-if="ctrl.extra > 0">
            <label>+ {{ctrl.extra}} hidden flags enabled</label>
        </div>
    `,
    require: {
        "ngModel": "ngModel"
    },
    scope: {
        flags: "="
    },
    bindToController: true,
    controllerAs: "ctrl",
    controller: class {
        public ngModel;
        public flags;
        public checkbox = [];
        public extra = 0;
        public disabled;

        constructor($attrs) {
            $attrs.$observe("disabled", disabled => this.disabled = disabled);
        }

        update() {
            this.ngModel.$setViewValue([...this.checkbox]);
        }

        $onInit() {
            // tslint:disable: no-bitwise
            this.ngModel.$formatters.push((x: number) => {
                x = x || 0;
                return this.flags.map((flag, i) => !!(x & (1 << i)));
            })
            this.ngModel.$parsers.push((x: boolean[]) => {
                let val = this.ngModel.$modelValue || 0;

                for (let i = 0; i < this.flags.length; i++) {
                    val &= ~(1 << i);
                    if (x[i]) val |= 1 << i;
                }

                return val;
            })
            this.ngModel.$render = () => {
                this.checkbox = this.ngModel.$viewValue;
                this.extra = 0;
                let val = this.ngModel.$modelValue || 0;
                for (let i = this.flags.length; i < 31; i++) {
                    if (!!(val & (1 << i))) this.extra++;
                }
            }
        }

    },
})