import * as angular from "angular";
import WorkspaceCtrl from "./WorkspaceCtrl";

export default angular.module("inphinity.app.workspace", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("workspace", {
                url: "/workspace",
                templateUrl: "html/workspace.html",
                controller: WorkspaceCtrl,
                controllerAs: "ctrl",
                resolve: WorkspaceCtrl.$resolve
            })
    })
    .name;
