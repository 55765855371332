import SettingsService from "./SettingsService";

let destinationUrl: string;

export function setDestinationUrl(url: string) {
    destinationUrl = url;
}

export default class SwitchTenantCtrl {
    static $resolve = {
        profile: (SettingsService: SettingsService) => SettingsService.getCachedProfile()
    }

    constructor(public profile, private SettingsService: SettingsService, private $state) {
    }

    async switchTenant(tenantId) {
        await this.SettingsService.switchTenant(tenantId);
        const config = window["inphinity-config"];
        window.location.hash = destinationUrl || ("#!" + (config.home || '/store?sort=-creationDate'));
        setDestinationUrl(undefined);
    }
}
