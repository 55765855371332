export interface WorkerInterface {
    (...args: any[]): any,

    isWorking(): boolean,

    hasFailed(): boolean,

    getError(): any,

    isDone(): boolean,

    isPristine(): boolean,

    reset(): void
}

export default function Worker(job): WorkerInterface {
    var workQueue = 0;
    var hasFailed = false;
    var lastError = undefined;
    var done = false;

    var fn = <WorkerInterface>function (...args: any[]) {
        hasFailed = false;
        done = false;
        workQueue++;
        let onErr = (err) => {
            hasFailed = true;
            workQueue--;
            lastError = err;
            throw err;
        };
        try {
            var result = job.apply(this, arguments);
            if (result && result.then) {
                result = result.then(
                    x => {
                        workQueue--;
                        done = true;
                        return x;
                    },
                    onErr
                )
            } else {
                workQueue--;
                done = true;
            }
        } catch (err) {
            onErr(err);
        }
        return result;
    }
    fn.isWorking = function () {
        return workQueue > 0
    }
    fn.hasFailed = function () {
        return hasFailed;
    }
    fn.getError = function () {
        return lastError;
    }
    fn.isDone = function () {
        return done;
    }
    fn.isPristine = function () {
        return !done && workQueue == 0 && !hasFailed;
    }
    fn.reset = function () {
        workQueue = 0;
        hasFailed = false;
        lastError = undefined;
        done = false;
    }
    return fn;
}

export {Worker};
