import * as angular from "angular";
import ProfileCtrl from "./ProfileCtrl";
import AddUserCtrl from "./AddUserCtrl";
import SettingsService from "./SettingsService";
import SwitchTenantCtrl, {setDestinationUrl} from "./SwitchTenantCtrl";

let _ss = null;
let _as = null;

export default angular.module("emark.msp.settings", [])
    .config(function routes($stateProvider, $transitionsProvider, $injector) {
        $stateProvider
            .state("profile", {
                url: "/profile",
                templateUrl: "html/profile.html",
                controller: ProfileCtrl,
                resolve: ProfileCtrl.$resolve,
                controllerAs: "ctrl"
            })
            .state("user-add", {
                url: "/user/add",
                template: require("./addUser.html").default,
                controller: AddUserCtrl,
                resolve: AddUserCtrl.$resolve,
                controllerAs: "ctrl"
            })
            .state("user-edit", {
                url: "/user/:id",
                templateUrl: "html/profile.html",
                controller: ProfileCtrl,
                resolve: ProfileCtrl.$resolve,
                controllerAs: "ctrl"
            })
            .state("switch-tenant", {
                url: "/switch-tenant",
                template: require("./switchTenant.html").default,
                controller: SwitchTenantCtrl,
                resolve: SwitchTenantCtrl.$resolve,
                controllerAs: "ctrl"
            })
        // redirect to profile if incomplete
        $transitionsProvider.onEnter({}, (trans) => {
            if (trans.to().name != "switch-tenant") {
                return _as.waitLogin().then(loggedIn => {
                    if (loggedIn) {
                        return _ss.getCachedProfile().then(profile => {
                            if (profile && !profile.tenant) {
                                console.log("tenant not selected");
                                setDestinationUrl(window.location.hash);
                                trans.router.stateService.transitionTo("switch-tenant");
                            }
                            return true;
                        })
                    }
                    return true;
                }).then(null, err => true)
            } else if (trans.to().name != "profile") {
                return _as.waitLogin().then(loggedIn => {
                    if (loggedIn) {
                        return _ss.getCachedProfile().then(profile => {
                            console.log("checking profile", profile)
                            if (!profile) {
                                console.log("profile incomplete");
                                trans.router.stateService.transitionTo("profile");
                            }
                            return true;
                        })
                    }
                    return true;
                }).then(null, err => true)
            }
            return true;
        })
    })
    .run((SettingsService, AuthService) => {
        _ss = SettingsService
        _as = AuthService
    })
    .service("SettingsService", SettingsService)
    .name;
