import {SubscriptionInfo, SubscriptionOrder} from "../../billing";

export default class SubscriptionService {
    constructor(private ApiService) {
    }

    getMySubscriptions(): Promise<SubscriptionInfo[]> {
        return this.ApiService.get("/api/billing/subscriptions").then(r => r.data);
    }

    confirmOrder(orderItems: SubscriptionOrder[]): Promise<any> {
        return this.ApiService.post("/api/billing/orders", orderItems).then(r => r.data);
    }

    getMyAccount() {
        return this.ApiService.get("/api/billing/account").then(r => r.data);
    }
}