export default () => ({
    template: `<input type="text" class="form-control" ng-model="ctrl.input" ng-blur="ctrl.update()" ng-disabled="ctrl.disabled">`,
    require: {
        "ngModel": "ngModel"
    },
    scope: {
        numeric: "=",
        star: "="
    },
    bindToController: true,
    controllerAs: "ctrl",
    controller: class {
        public input;
        public ngModel;
        public numeric;
        public star;
        public disabled;

        constructor($attrs) {
            $attrs.$observe("disabled", disabled => this.disabled = disabled);
        }

        arrayOrValue(x) {
            if (x.length === 1) x = x[0];
            return x;
        }

        $onInit() {
            this.ngModel.$formatters.push((x) => {
                x = x || [];
                if (typeof x === "number" || typeof x === "string") return ("" + x);
                return x.join(", ");
            })
            this.ngModel.$parsers.push((x) => {
                return this.arrayOrValue(this.parseString(x));
            })
            this.ngModel.$render = () => {
                this.input = this.ngModel.$viewValue;
            }
        }

        update() {
            this.input = this.parseString(this.input).join(", ");
            this.ngModel.$setViewValue(this.input);
        }

        parseString(input) {
            let r: any = ("" + input).split(" ").join(",").split(";").join(",").split(",").map(x => x.trim()).filter(x => x != "")
            if (this.numeric) {
                r = r.map(r => r === "*" ? r : +r.replace(/\D/g, "")).filter(r => r === "*" || !isNaN(r));
            }
            return r;
        }
    },
})
