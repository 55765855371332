import {Worker} from "../../util";
import * as moment from "moment";

export default class TenantsCtrl {
    public users = [];
    public err;
    public Ferr;
    public Uerr;
    public Aerr;
    public r;
    public Fr;
    public Ur;
    public Ar;
    public newUser;
    public newTenant;
    public portals = [];
    public regions = [];
    public tenantId = undefined
    public tenant;
    public planningUsers;

    constructor(private ApiService, private DialogService, private UserService, private AppService, private config, private FormsService, $state) {
        if ($state.params.id && !isNaN($state.params.id)) {
            this.tenantId = $state.params.id
        } else {
            this.tenantId = undefined
        }

        this.load = Worker(this.load);
        this.deleteUser = Worker(this.deleteUser);
        this.disableUser = Worker(this.disableUser);
        this.enableUser = Worker(this.enableUser);
        this.inviteUser = Worker(this.inviteUser);
        this.setQlikclouddomain = Worker(this.setQlikclouddomain);
        this.allowPartnerPipeline = Worker(this.allowPartnerPipeline);
        this.load();
        this.listPortals();

        this.newUser = {
            tenantId: "",
            name: "",
            email: "",
            isOwner: 0,
            isAdmin: 0,
            skipSendMail: 0
        };

        this.newTenant = {
            create: 0,
            name: "",
            billingAddress: "<temp>",
            country: "XX",
            portalInstanceId: this.portals[0],
            industry: "<temp>"
        };

        this.regions = config.allowedFormsRegions;
    }

    async load() {
        if (this.tenantId) {
            this.tenant = (await this.ApiService.get("/api/admin/tenant/" + this.tenantId)).data
            this.tenant.partner = !!this.tenant.partner
            this.tenant.newUserLimit = this.tenant.limit.userLimit
            this.tenant.newPlanUserLimit = this.tenant.userPlanLimit
            this.tenant.newFormsLimit = this.tenant.formsLimit.formLimit
            this.tenant.newFormsType = this.tenant.formsLicenseType || 'trial'
            this.planningUsers = this.tenant.users.filter(u => u.planningAllowed)
            console.log(this.tenant)
        } else {
            this.tenant = undefined;
        }
    }

    async listPortals() {
        this.portals = (await this.ApiService.get("/api/messages/portals")).data
    }

    async deleteUser(user) {
        this.Uerr = this.Ur = "";
        let result = await this.DialogService.confirm("Delete " + user.name + " from tenant " + this.tenant.name + "? User will be deleted.");
        if (result) {
            user.deletePending = true;
            try {
                this.Ur = (await this.ApiService.delete("/api/admin/tenant/" + this.tenant.id + "/user/" + user.id, null)).data
            } catch (e) {
                this.Uerr = e.data.message;
                user.deletePending = false;
            }
            await this.load();
        }
    }

    async disableUser(tenant, user) {
        this.Uerr = this.Ur = "";
        let result = await this.DialogService.confirm("Disable " + user.name + " ? User will be disabled for a quarantine period, then deleted.");
        if (result) {
            user.disablePending = true;
            try {
                this.Ur = (await this.ApiService.post("/api/admin/tenant/" + tenant.id + "/user/" + user.id + "/disable", null)).data
            } catch (e) {
                this.Uerr = e.data.message;
                user.disablePending = false;
            }
            await this.load();
        }
    }

    async enableUser(tenant, user) {
        this.Uerr = this.Ur = "";
        let result = await this.DialogService.confirm("Re-enable user " + user.name + " ?");
        if (result) {
            user.enablePending = true;
            try {
                this.Ur = (await this.ApiService.post("/api/admin/tenant/" + tenant.id + "/user/" + user.id + "/enable", null)).data
            } catch (e) {
                this.Uerr = e.data.message;
                user.enablePending = false;
            }
            await this.load();
        }
    }

    async inviteUser(tenantId) {
        this.Uerr = this.Ur = "";
        this.newUser.invitePending = true;
        this.newUser.tenantId = tenantId;

        try {
            this.Ur = (await this.ApiService.post("/api/admin/user/invite", this.newUser)).data;
        } catch (e) {
            this.Uerr = e.data.message;
        }
        this.newUser.invitePending = false;

        this.newUser = {
            tenantId: "",
            name: "",
            email: "",
            isOwner: 0,
            isAdmin: 0,
            isFormsAdmin: 0,
            skipSendMail: 0
        };
        await this.load();
    }


    async resetPassword(user) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Reset password and resend invitaion mail for user " + user.name + " ?");
        if (result) {
            user.resetPending = true;
            try {
                this.Ur = (await this.ApiService.post("/api/admin/user/" + user.id + "/resetPassword", null)).data;
                //this.Ur = await this.UserService.resetPassword(user.id);
            } catch (e) {
                this.err = e.data.message;
                user.resetPending = false;
            }
            user.resetPending = false;
            //await this.load();
        }
    }

    async createTenant() {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Create new tenant " + this.newTenant.name + " ?");
        if (result) {
            this.newTenant.createPending = true;
            try {
                var id = (await this.ApiService.post("/api/admin/tenant/new", this.newTenant)).data;
            } catch (e) {
                this.err = e.data.message;
            }
            this.newTenant.createPending = false;
            this.newTenant = {
                create: 0,
                name: "",
                billingAddress: "<temp>",
                country: "XX",
                portalInstanceId: this.portals[0],
                industry: "<temp>"
            };
            this.tenantId = id
            await this.load();
        }
    }

    async deleteTenant(tenant) {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Delete " + tenant.name + " ? Tenant will be deleted.");
        if (result) {
            tenant.deletePending = true;
            try {
                this.r = (await this.ApiService.delete("/api/admin/tenant/" + tenant.id, null)).data
            } catch (e) {
                this.err = e.data.message;
                tenant.deletePending = false;
            }
            this.tenantId = undefined
            await this.load();
        }
    }

    async updateUserLimit(tenant) {
        tenant.updateLimitPending = true;
        var update = {
            tenantId: tenant.id,
            limit: tenant.newUserLimit
        }
        var r = (await this.ApiService.post("/api/admin/users/limit", update)).data

        tenant.updateLimitPending = false
        await this.load();
    }

    async updatePlanningUserLimit(tenant) {
        tenant.updatePlanLimitPending = true;
        var update = {
            tenantId: tenant.id,
            limit: tenant.newPlanUserLimit
        }
        try {
            this.r = (await this.ApiService.post("/api/admin/users/planlimit", update)).data
        } catch (e) {
            this.err = e.data.message;
        }
        tenant.updatePlanLimitPending = false
        await this.load();
    }


    async updateFormsLimit(tenant) {
        tenant.updateFormsLimitPending = true;
        var update = {
            tenantId: tenant.id,
            limit: tenant.newFormsLimit
        }
        var r = (await this.ApiService.post("/api/admin/forms/limit", update)).data

        tenant.updateFormsLimitPending = false
        await this.load();
    }

    async deleteForm(tenantId, form) {
        this.Ferr = this.Fr = "";
        let result = await this.DialogService.confirm("Delete " + form.formId + " ? Form and form data will be deleted.");
        if (result) {
            form.deletePending = true;
            try {
                this.Fr = (await this.ApiService.post("/api/admin/form/" + form.formId, {tenantId: tenantId})).data
            } catch (e) {
                this.Ferr = e.data.message;
                form.deletePending = false;
            }
            await this.load();
        }
    }

    async createNewForm(tenant) {
        this.Ferr = this.Fr = "";
        tenant.addForm = true;
        try {
            console.log(tenant.newForm)
            this.Fr = (await this.ApiService.post("/api/admin/form/create", {
                tenantId: tenant.id,
                type: tenant.newForm.type,
                description: tenant.newForm.description,
                region: tenant.newForm.region,
                changelog: tenant.newForm.changelog
            })).data;
        } catch (e) {
            this.Ferr = e.data.message;
        }
        tenant.addForm = false;

        await this.load();
    }


    async convertForm(tenantId, form) {
        this.Ferr = this.Fr = "";
        const targetType = (form.type == 'general-mongo' || form.type == 'commentobj-mongo' || form.type == 'planning-mongo') ? 'XML' : 'Mongo'
        let result = await this.DialogService.confirm("Convert " + form.formId + " to " + targetType + "type?");
        if (result) {
            form.convertPending = true;
            try {
                this.Fr = (await this.ApiService.post("/api/admin/form/" + form.formId + "/convert", {
                    tenantId: tenantId,
                    targetType: targetType
                })).data
            } catch (e) {
                this.Ferr = e.data.message;
                form.convertPending = false;
            }
            await this.load();
        }
    }

    async startFormsTrial(tenant) {
        tenant.startFormsTrialPending = true;
        this.Fr = (await this.ApiService.post("/api/admin/form/" + tenant.id + "/starttrial", null)).data
        tenant.startFormsTrialPending = false
        await this.load();
    }

    async startForms(tenant) {
        tenant.startFormsPending = true;

        console.log(this.tenant.newFormsEndDate)
        this.Fr = (await this.ApiService.post("/api/admin/form/" + tenant.id + "/start", {
            date: moment(tenant.newFormsEndDate).format("DD-MM-YYYY"),
            type: tenant.newFormsType
        })).data
        tenant.startFormsPending = false
        await this.load();
    }

    async updatePartner(tenant) {
        tenant.updatePartnerPending = true;
        let update = {
            tenantId: tenant.id,
            partner: tenant.partner
        }
        let r = (await this.ApiService.post("/api/admin/tenant/partner", update)).data

        tenant.updatePartnerPending = false
        await this.load();

    }

///////////////////////////////////////////////////// APPS //////////////////////////////////////////////////////////////

    async deleteSubscription(app) {
        let result = await this.DialogService.confirm("Delete " + app.name + "?");
        if (result) {
            app.deletePending = true;
            await this.ApiService.delete("/api/billing/subscription/" + app.subscription.id).data
            await this.load();
        }
    }

    async resetForm(app) {
        let result = await this.DialogService.confirm("Reset app " + app.id + " forms? Forms data will be restored to default.");
        if (result) {
            app.formsResetPending = true;
            try {
                (await this.ApiService.post("/api/admin/app/" + app.id + "/resetForms", {tenant: app.tenant})).data
            } catch (e) {
            }
            app.formsResetPending = false;
        }
    }

    async deleteApp(app) {
        let result = await this.DialogService.confirm("Delete " + app.name + "?");
        if (result) {
            app.deletePending = true;
            (await this.ApiService.post("/api/admin/app/" + app.id + "/delete", {tenant: app.tenant})).data
            await this.load();
        }
    }

    async upgradeApp(app) {
        let result = await this.DialogService.confirm("Upgrade " + app.name + "?");
        if (result) {
            app.upgradePending = true;
            (await this.ApiService.post("/api/admin/app/" + app.id + "/upgrade", {tenant: app.tenant})).data
            app.upgradePending = false;
            await this.load();
        }
    }

    async reloadApp(app) {
        app.reload.status = 1;
        await this.AppService.reloadApp(app.id);
        this.load();
    }

    getReloadStatusInfo(status) {
        const statusMap = {
            0: {text: "Never started", class: "info"},
            1: {text: "Triggered", class: "info", busy: true},
            2: {text: "Started", class: "info", busy: true},
            3: {text: "Queued", class: "info", busy: true},
            4: {text: "Abort initiated", class: "warning", busy: true},
            5: {text: "Aborting", class: "warning", busy: true},
            6: {text: "Aborted", class: "warning"},
            7: {text: "Success", class: "success"},
            8: {text: "Failed", class: "danger"},
            9: {text: "Skipped", class: "warning"},
            10: {text: "Retrying", class: "warning", busy: true},
            11: {text: "Error", class: "danger"},
            12: {text: "Reset", class: "info"},
        }
        if (statusMap[status]) return statusMap[status];
        return {text: "Unknown", class: "danger"};
    }

    shouldShowReloadTime(app) {
        return app.reload.status > 0 && new Date(app.lastReloadTime) > new Date(app.createdDate);
    }

    async setQlikclouddomain() {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Update cloud domain to " + this.tenant.qlikCloudDomain + " ?");
        if (result) {
            try {
                await this.FormsService.setQlikclouddomain(this.tenant.id, this.tenant.qlikCloudDomain);
                this.DialogService.success("Domain updated!")
            } catch (e) {
                this.err = e.data.message;
            }
            await this.load();
        }
    }


    async allowPartnerPipeline(user) {
        this.Uerr = this.Ur = "";
        if (user.partnerPipeline) {
            let result = await this.DialogService.confirm("Disallow " + user.name + " from tenant " + this.tenant.name + " to edit partner pipeline?");
            if (result) {
                user.pipeline = true;
                try {
                    this.Ur = (await this.ApiService.post("/api/admin/tenant/" + this.tenant.id + "/user/" + user.id + "/disallowPipeline", null)).data
                    await this.load();
                } catch (e) {
                    this.Uerr = e.data.message;
                }
                user.pipeline = false;
            }
        } else {
            let result = await this.DialogService.confirm("Allow " + user.name + " from tenant " + this.tenant.name + " to edit partner pipeline?");
            if (result) {
                user.pipeline = true;
                try {
                    this.Ur = (await this.ApiService.post("/api/admin/tenant/" + this.tenant.id + "/user/" + user.id + "/allowPipeline", null)).data
                    await this.load();
                } catch (e) {
                    this.Uerr = e.data.message;
                }
                user.pipeline = false;
            }
        }
    }


    async updateClientId() {
        this.err = this.r = "";
        let result = await this.DialogService.confirm("Update client id to " + this.tenant.clientId + " ?");
        if (result) {
            try {
                await this.ApiService.post("/api/admin/tenant/" + this.tenant.id + "/clientId", {"clientId": this.tenant.clientId})
                this.DialogService.success("Client id updated!")
            } catch (e) {
                this.err = e.data.message;
            }
            await this.load();
        }
    }
}
