import * as $ from "jquery";

export interface ApiServiceInterface {
    get(endpoint);

    post(endpoint, data);

    delete(endpoint);
}

let redirectHandled = false;

function handlePortalRedirection(response) {
    if (response.status == 421) {
        if (!redirectHandled) {
            redirectHandled = true;
            alert("You logged into different version of inphinity.app portal than you are registered with.\nYou will be redirected to " + response.data.redirect);
            if (response.data.redirect) {
                window.location.href = response.data.redirect;
            } else {
                alert("Redirect location not found");
            }
        }
        throw response.data.message;
    }
    return response;
}

function handlePortalRedirectionError(err) {
    throw handlePortalRedirection(err);
}

export default class ApiService implements ApiServiceInterface {
    constructor(private AuthService, private $http, private FileUploader) {
    }

    private async getHeaders() {
        await this.AuthService.waitLogin();
        let token = this.AuthService.getAccessToken();
        if (token) {
            return {"Authorization": "Bearer " + token}
        }
        return {};
    }

    async get(endpoint) {
        return this.$http.get(endpoint, {
            headers: await this.getHeaders()
        }).then(handlePortalRedirection, handlePortalRedirectionError);
    }

    async post(endpoint, data) {
        return this.$http.post(endpoint, data, {
            headers: await this.getHeaders()
        });
    }

    async postXml(endpoint, data) {
        await this.AuthService.waitLogin();
        let token = this.AuthService.getAccessToken();
        let h = {"Authorization": "Bearer " + token, "Content-type": "text/xml"}
        return this.$http.post(endpoint, data, {
            headers: h
        });
    }

    async delete(endpoint) {
        return this.$http.delete(endpoint, {
            headers: await this.getHeaders()
        });
    }

    async uploader(endpoint) {
        return new this.FileUploader({
            url: endpoint,
            headers: await this.getHeaders()
        });
    }

    async postForm(endpoint, data) {
        var $form = $("<form>")
            .attr("method", "POST")
            .attr("action", endpoint)
            .attr("target", "_blank");
        $form.append($("<input>").attr("type", "hidden").attr("name", "header_authorization").val((await this.getHeaders()).Authorization));
        $form.append($("<input>").attr("type", "hidden").attr("name", "data").val(JSON.stringify(data)));
        $("body").append($form);
        $form.submit();
        $form.remove();
    }
}
