import * as angular from "angular";
import MessagesCtrl from "./MessagesCtrl";

export default angular.module("inphinity.app.admin.messages", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("admin-messages", {
                url: "/admin/messages",
                template: require("./messages.html").default,
                controllerAs: "ctrl",
                controller: MessagesCtrl,
            })
    })
    .name;
