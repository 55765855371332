import * as angular from "angular";
import ErrorCtrl from "./ErrorCtrl";
import "./error.css";

export default angular.module("inphinity.app.errors", [])
    .config(function routing($stateProvider) {
        $stateProvider
            .state("error", {
                url: "/error",
                template: require("./error.html").default,
                params: {error: null},
                controller: ErrorCtrl,
                controllerAs: "ctrl",
                resolve: {
                    error: function ($stateParams) {
                        return $stateParams.error;
                    }
                }
            })
    })
    .run(function errorHandler($transitions, $state) {
        $transitions.onError({}, function (transition) {
            var err = transition.error();
            console.log("Error state", err);
            if (err && err.type == 6) {
                $state.go('error', {error: err.detail}, {location: false});
            }
        });
    })
    .directive("mspErrorMessage", function () {
        return {
            restrict: "E",
            transclude: true,
            scope: {
                "worker": "="
            },
            template: require("./ErrorMessage.html").default,
            controller: function () {
            },
            controllerAs: "ctrl"
        }
    })
    .name;
