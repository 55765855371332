import ApiService from "../api/ApiService";

export default class MessageService {
    constructor(private ApiService: ApiService) {
    }

    public async getNotifications() {
        return (await this.ApiService.get("/api/messages/notifications")).data;
    }

    public async markDismissed(id) {
        return (await this.ApiService.delete("/api/messages/" + id)).data;
    }

    public async markSeen(id) {
        return (await this.ApiService.get("/api/messages/" + id)).data;
    }

    public async getAllNotifications() {
        return (await this.ApiService.get("/api/messages/notifications/all")).data;
    }

    public async sendNotifications(message) {
        return (await this.ApiService.post("/api/messages/broadcast/all", message)).data;
    }
}
