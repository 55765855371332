import * as angular from "angular";
import SchedulerCtrl from "./SchedulerCtrl";

export default angular.module("inphinity.app.admin.scheduler", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("admin-scheduler", {
                url: "/admin/scheduler",
                template: require("./scheduler.html").default,
                controllerAs: "ctrl",
                controller: SchedulerCtrl,
            })
    })
    .name;
