import {Worker} from "../../util";
import AppManagementCtrlBase from "../../apps/AppManagementCtrlBase"

export default class AppsCtrl extends AppManagementCtrlBase {
    constructor(config, DialogService, AppService, $timeout, ApiService) {
        super(config, DialogService, AppService, $timeout, ApiService)
        this.reloadAppList = Worker(this.reloadAppList.bind(this));
        this.deleteSubscription = Worker(this.deleteSubscription.bind(this));
        this.deleteApp = Worker(this.deleteApp.bind(this));
        this.upgradeApp = Worker(this.upgradeApp.bind(this));
        this.reloadAppList();
    }

    async reloadAppList() {
        this.apps = await this.AppService.getAllApps();
    }

    async deleteSubscription(app) {
        let result = await this.DialogService.confirm("Delete " + app.name + "?");
        if (result) {
            app.deletePending = true;
            await this.ApiService.delete("/api/billing/subscription/" + app.subscription.id).data
            await this.reloadAppList();
        }
    }

    async resetForm(app) {
        let result = await this.DialogService.confirm("Reset app " + app.id + " forms? Forms data will be restored to default.");
        if (result) {
            app.formsResetPending = true;
            try {
                (await this.ApiService.post("/api/admin/app/" + app.id + "/resetForms", {tenant: app.tenant})).data
            } catch (e) {
            }
            app.formsResetPending = false;
        }
    }

    async deleteApp(app) {
        let result = await this.DialogService.confirm("Delete " + app.name + "?");
        if (result) {
            app.deletePending = true;
            (await this.ApiService.post("/api/admin/app/" + app.id + "/delete", {tenant: app.tenant})).data
            await this.reloadAppList();
        }
    }

    async upgradeApp(app) {
        let result = await this.DialogService.confirm("Upgrade " + app.name + "?");
        if (result) {
            app.upgradePending = true;
            (await this.ApiService.post("/api/admin/app/" + app.id + "/upgrade", {tenant: app.tenant})).data
            app.upgradePending = false;
            await this.reloadAppList();
        }
    }
}
