// tslint:disable-next-line: ban-types
export function debounce<F extends Function>(delayMs, callback: F): F {
    let timer;
    return function () {
        let args = arguments;
        if (timer) clearTimeout(timer);
        return new Promise((resolve, reject) => {
            timer = setTimeout(() => resolve(callback.apply(this, args)), delayMs);
        })
    } as any as F;
};