import * as angular from "angular";
import FormsCtrl from "./FormsCtrl";

export default angular.module("inphinity.app.admin.forms", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("admin-forms", {
                url: "/admin/forms",
                template: require("./forms.html").default,
                controllerAs: "ctrl",
                controller: FormsCtrl,
            })
    })
    .name;
