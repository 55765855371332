const currencyFormats = {
    EUR: {symbol: '€', decimal: ",", thousand: " ", precision: 2, prefix: false},
    AUD: {symbol: 'AU$', decimal: ".", thousand: ",", precision: 2, prefix: true},
}

export function formatNumber(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - (+i)).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export function formatMoney(value, currencyCode, precision = null) {
    if (value === undefined || value === null || isNaN(value)) return "-";
    let format = {...currencyFormats[currencyCode]};
    if (!format) format = {symbol: currencyCode, decimal: ",", thousand: " ", precision: 2, prefix: false};
    if (precision !== null) format.precision = precision;
    let valueStr = formatNumber(value, format.precision, format.decimal, format.thousand);
    if (format.prefix) valueStr = format.symbol + ' ' + valueStr;
    else valueStr = valueStr + ' ' + format.symbol;
    return valueStr;
}