import * as angular from "angular";
import SubscriptionCtrl from "./SubscriptionCtrl";
import SubscriptionService from "./SubscriptionService";

export default angular.module("inphinity.app.subscription", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("subscription", {
                url: "/subscription",
                template: require("./subscription.html").default,
                controller: SubscriptionCtrl,
                controllerAs: "ctrl",
                resolve: SubscriptionCtrl.$resolve
            })
    })
    .service("SubscriptionService", SubscriptionService)
    .name;
