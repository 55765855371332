import * as angular from "angular";
import BillingCtrl from "./BillingCtrl";
import BillingService from "./BillingService";
import "./billing.css";

export default angular.module("inphinity.app.billing", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("billing", {
                url: "/billing",
                template: require("./billing.html").default,
                controllerAs: "ctrl",
                controller: BillingCtrl,
                resolve: BillingCtrl.$resolve
            })
    })
    .service("BillingService", BillingService)
    .name;
