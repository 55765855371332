import * as angular from "angular";
import LicensesCtrl from "./LicensesCtrl";

export default angular.module("inphinity.app.licenses.full", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("licenses/full", {
                url: "/licenses/full?customer&computerId&note&company&email&version&product",
                template: require("./licenses.html").default,
                controllerAs: "ctrl",
                controller: LicensesCtrl,
            })
    })
    .name;
