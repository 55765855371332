import {Worker} from "../util";
import SettingsService from "./SettingsService";

export default class AddUserCtrl {
    static $resolve = {}

    public roles;
    public newUser;
    private admin;
    private owner

    private role = 'User';
    private changeRoleDisabled = 0;

    constructor(private SettingsService: SettingsService) {
        this.newUser = {
            name: "",
            email: "",
            isOwner: 0,
            isAdmin: 0,
            sendInvite: '1'
        };
        this.save = Worker(this.save.bind(this));
        this.admin = SettingsService.isAdmin();
        this.owner = SettingsService.isOwner();
        this.role = "User";
        this.roles = ['User'];
        if (this.admin) {
            this.roles.push('Admin');
        }
        if (this.owner) {
            this.roles.push('Owner');
        }
    }

    modified(r) {
        if (r == 'User') {
            this.newUser.isAdmin = 0;
            this.newUser.isOwner = 0;
        }
        if (r == 'Admin') {
            this.newUser.isAdmin = 1;
            this.newUser.isOwner = 0;
        }
        if (r == 'Owner') {
            this.newUser.isAdmin = 1;
            this.newUser.isOwner = 1;
        }
    }

    async save() {
        await this.SettingsService.inviteUser(this.newUser);
    }

    isAdmin() {
        return this.admin;
    }

    isOwner() {
        return this.owner;
    }
}
