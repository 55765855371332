import * as angular from "angular";
import LicensesCtrl from "./LicensesCtrl";
import LicensesPartnerCtrl from "./LicensesPartnerCtrl";
import ListEditor from "./ListEditor";
import ProductEnabledCheckbox from "./ProductEnabledCheckbox";
import BinaryFlagEditor from "./BinaryFlagEditor";

export default angular.module("inphinity.app.licenses.suite", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("licenses-suite", {
                url: "/licenses/suite?customer&computerId&note&company&email&version&product",
                template: require("./licenses.html").default,
                controllerAs: "ctrl",
                controller: LicensesCtrl,
            })
    })
    .config(function routes($stateProvider) {
        $stateProvider
            .state("licenses-partner-suite", {
                url: "/licenses/partner-suite?customer&computerId&note&company&email&version&product",
                template: require("./licenses-partner.html").default,
                controllerAs: "ctrl",
                controller: LicensesPartnerCtrl,
            })
    })
    .directive("listEditor", ListEditor)
    .directive("productEnabledCheckbox", ProductEnabledCheckbox)
    .directive("binaryFlagEditor", BinaryFlagEditor)
    .directive("dateAsTimestamp", () => ({
        restrict: "A",
        require: "ngModel",
        link: (scope, element, attrs, ngModel) => {
            ngModel.$parsers.push(x => Math.round(x.getTime() / 1000))
            ngModel.$formatters.push(x => new Date(x * 1000));
        }
    }))
    .directive("utcMidnightTimestamp", () => ({
        restrict: "A",
        require: "ngModel",
        link: (scope, element, attrs, ngModel) => {
            ngModel.$parsers.push((x: Date) => Math.round(Date.UTC(x.getFullYear(), x.getMonth(), x.getDate()) / 1000))
            ngModel.$formatters.push(x => new Date(x * 1000));
        }
    }))
    .name;
