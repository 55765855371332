import ApiService from "../api/ApiService";

export default class BillingService {
    constructor(private ApiService: ApiService) {
    }

    async generateProForma(invoiceData) {
        return (await this.ApiService.post("/api/billing/account/charge/proforma", invoiceData)).data;
    }

    async getDefaultPaymentDetails() {
        return (await this.ApiService.get("/api/billing/proforma/default")).data;
    }

    async getLastTransactions() {
        return (await this.ApiService.get("/api/billing/bill")).data;
    }

    async getTenantProForma() {
        return (await this.ApiService.get("/api/billing/proforma/all")).data;
    }
}
