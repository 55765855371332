const ALL_FEATURES = 0x7FFFFFFF;
export enum FormsFeatures {
    MULTINODE = 1,
    COMMENTING = 1 << 1,
    SSE = 1 << 2,
    COLLABORATION = 1 << 3,
    HYBRID = 1 << 4,
    XML = 1 << 5,
    SQL = 1 << 6,
    QVD = 1 << 7,
    ACTIONS = 1 << 8,
    QVD_ENCRYPTION = 1 << 9,
    MODERN_UI = 1 << 10,
    ALL = ALL_FEATURES
}

export enum FormsSaaSFeatures {
    SQL = 1,
    ALL = ALL_FEATURES
}

export enum FlowFeatures {
    ACTIONS = 1,
    CUSTOM_NODES = 1 << 1,
    WIZARD = 1 << 2,
    ALL = ALL_FEATURES
}

export enum MoleFeatures {
    OCR = 1,
    MAIL = 1 << 1,
    CLASSIFIER = 1 << 2,
    SSE = 1 << 3,
    ALL = ALL_FEATURES
}

