import * as angular from "angular";
import AppManagementCtrl from "./AppManagementCtrl";
import AppService from "./AppService";

export default angular.module("inphinity.app.apps", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("app-management", {
                url: "/apps/manage",
                template: require("./app-management.html").default,
                controllerAs: "ctrl",
                controller: AppManagementCtrl,
                resolve: AppManagementCtrl.$resolve
            })
    })
    .service("AppService", AppService)
    .name;
