import {Worker} from "../../util";
import * as moment from "moment";

export default class LicensesCtrl {
    public licenses = [];
    public fullLicenses = [];
    public newData;
    public err;
    public generatedLicense;

    public showFull;
    public showTrial;
    public showDetails;
    public hostname = window.location.host;

    public products = ["Forms", "MoleUDC", "inphinity-flow"];

    constructor(private ApiService, private DialogService, private UserService, private SettingsService, $state) {
        this.load = Worker(this.load);
        this.newTrial = Worker(this.newTrial);
        this.newLicense = Worker(this.newLicense);
        void this.load();

        this.newData = {
            computerId: $state.params.computerId || "",
            company: $state.params.company || "",
            description: $state.params.note || "",
            product: ($state.params.product == "Mole UDC" ? "MoleUDC" : $state.params.product) || this.products[0],
            name: $state.params.customer || "",
            email: $state.params.email || "",
            version: $state.params.version || "8.0.0",
            exDate: "",
            limit: "",
            contact: "",
            licensedYears: "",
            type: 'Full',
            trialType: 'Trial 2W',
            formsLimit: "0",
            moleOcr: "0",
            moleMail: "0"
        };

        this.generatedLicense = false;

        if ($state.params.computerId != undefined || $state.params.product == 'inphinity-flow') {
            this.newData.createTrial = 1;
        }

        this.showFull = false;
        this.showTrial = false;
        this.showDetails = true;
    }

    async load() {
        this.licenses = (await this.ApiService.get("/api/licenses/trialLicenses")).data
        this.fullLicenses = (await this.ApiService.get("/api/licenses/fullLicenses")).data
    }

    async newTrial() {
        this.reset();

        let isComputerIdRequired = this.newData.product != "inphinity-flow";
        let isLimitRequired = this.newData.product != "inphinity-flow";

        if ((isComputerIdRequired && this.newData.computerId == '0') || this.newData.company == '' || this.newData.name == '' || this.newData.email == '') {
            this.err = "Missing customer informations.";
            return;
        }
        if (isComputerIdRequired && isNaN(this.newData.computerId)) {
            this.err = "Please enter correct computerId.";
            return;
        }

        this.newData.Pending = true;

        if (this.newData.trialType == "Trial 2W") {
            this.newData.exDate = moment().add(14, 'days').toDate();
        }
        this.newData.date = moment(this.newData.exDate).format("DD-MM-YYYY");

        try {
            var key = (await this.ApiService.post("/api/licenses/trial", this.newData)).data;
            this.generatedLicense = {
                ...this.newData,
                key
            };
        } catch (e) {
            this.err = e.data.message;
        }

        this.newData.Pending = false;

        this.newData = {
            product: this.products[0],
            computerId: "",
            company: "",
            description: "",
            name: "",
            email: "",
            exDate: "",
            trialType: "Trial 2W",
            type: 'Full',
            version: this.newData.version,
            formsLimit: "0",
            moleOcr: "0",
            moleMail: "0"
        };
        await this.load();
    }

    async newLicense() {
        this.reset();
        let isComputerIdRequired = this.newData.product != "inphinity-flow";
        let isUserLimitRequired = this.newData.product == "Forms";
        let isThreadLimitRequired = this.newData.product == "MoleUDC";
        let isFormsLimitRequired = this.newData.product == "Forms";

        if ((isComputerIdRequired && this.newData.computerId == '0') || this.newData.company == '' || this.newData.name == '' || this.newData.email == '' || this.newData.exDate == '' || ((isUserLimitRequired || isThreadLimitRequired) && this.newData.limit == '') || this.newData.product == '') {
            this.err = "Missing informations.";
            return;
        }
        if (isComputerIdRequired && isNaN(this.newData.computerId)) {
            this.err = "Please enter correct computerId.";
            return;
        }

        if (isFormsLimitRequired && isNaN(this.newData.formsLimit)) {
            this.err = "Please enter correct forms limit.";
            return;
        }


        if (isUserLimitRequired && (this.setMaxUser(this.newData.limit) == 0 || isNaN(this.setMaxUser(this.newData.limit)))) {
            this.err = "Please enter correct user limit.\nValid limits: 1-100,200,300,400,500,1000,2000,3000,4000,5000,Unlimited/101 ";
            return;
        } else {
            this.newData.limit = this.setMaxUser(this.newData.limit)
        }

        if (isThreadLimitRequired && (this.newData.limit < 0 || this.newData.limit > 16)) {
            this.err = "Please enter correct thread limit.\n Valid values are 0-16 [0(unlimited),4(basic),16(premium)]?";
            return;
        }

        this.newData.Pending = true;
        this.newData.date = moment(this.newData.exDate).format("DD-MM-YYYY");
        try {

            var key = (await this.ApiService.post("/api/licenses/full", this.newData)).data;
            this.generatedLicense = {
                ...this.newData,
                key
            };
        } catch (e) {
            this.err = e.data.message;
        }

        this.newData.Pending = false;

        this.newData = {
            product: this.products[0],
            computerId: "",
            customer: "",
            description: "",
            email: "",
            name: "",
            exDate: "",
            trialType: "Trial 2W",
            type: "Full",
            formsLimit: "0",
            limit: "",
            moleOcr: "0",
            moleMail: "0"
        };
        await this.load();
    }

    async saveNote(license) {
        this.reset();
        try {
            var key = (await this.ApiService.post("/api/licenses/" + license.id + "/note", license)).data;
        } catch (e) {
            this.err = e.data.message;
        }

        license.edit = 0;
        await this.load();
    }

    async saveFullNote(license) {
        this.err = "";
        try {
            var key = (await this.ApiService.post("/api/licenses/" + license.id + "/noteFull", license)).data;
        } catch (e) {
            this.err = e.data.message;
        }
        license.edit = 0;
        await this.load();
    }

    async extendTrialLicense(license) {
        this.reset();
        this.newData = {
            computerId: license.computerId,
            company: license.company,
            description: license.description,
            product: license.product,
            name: license.name,
            email: license.email,
            version: license.version,
            exDate: "",
            limit: "",
            contact: "",
            licensedYears: "",
            type: 'Full',
            trialType: 'Trial 2W',
            formsLimit: "0"
        };

        this.newData.createFull = 0;
        this.newData.createTrial = 1;
    }

    async extendFullLicense(license) {
        this.reset();

        this.newData = {
            computerId: license.computerId,
            company: license.company,
            description: license.description,
            product: license.product,
            name: license.name,
            email: license.email,
            version: license.version,
            exDate: "",
            limit: this.getMaxUser(license.limit),
            contact: "",
            licensedYears: license.licensedYears,
            type: license.type,
            trialType: 'Trial 2W',
            formsLimit: "" + license.formsLimit + "",
            moleMail: "" + license.moleMail + "",
            moleOcr: "" + license.moleOcr + ""
        };

        this.newData.createFull = 1
        this.newData.createTrial = 0
    }

    getMaxUser(keyMaxUserValue) {
        var maxUsers = 1;
        if (keyMaxUserValue <= 100) maxUsers = keyMaxUserValue;
        else if (keyMaxUserValue == 101) return "Unlimited";
        else if (keyMaxUserValue == 120) maxUsers = 200;
        else if (keyMaxUserValue == 130) maxUsers = 300;
        else if (keyMaxUserValue == 140) maxUsers = 400;
        else if (keyMaxUserValue == 150) maxUsers = 500;
        else if (keyMaxUserValue == 210) maxUsers = 1000;
        else if (keyMaxUserValue == 220) maxUsers = 2000;
        else if (keyMaxUserValue == 230) maxUsers = 3000;
        else if (keyMaxUserValue == 240) maxUsers = 4000;
        else if (keyMaxUserValue == 250) maxUsers = 5000;
        else maxUsers = 1;

        return maxUsers;
    }


    setMaxUser(MaxUserValue) {
        var keyMaxUserValue = 0;
        if (MaxUserValue <= 100) keyMaxUserValue = MaxUserValue;
        else if (MaxUserValue == 101 || MaxUserValue == "Unlimited") keyMaxUserValue = 101;
        else if (MaxUserValue == 200) keyMaxUserValue = 120;
        else if (MaxUserValue == 300) keyMaxUserValue = 130;
        else if (MaxUserValue == 400) keyMaxUserValue = 140;
        else if (MaxUserValue == 500) keyMaxUserValue = 150;
        else if (MaxUserValue == 1000) keyMaxUserValue = 210;
        else if (MaxUserValue == 2000) keyMaxUserValue = 220;
        else if (MaxUserValue == 3000) keyMaxUserValue = 230;
        else if (MaxUserValue == 4000) keyMaxUserValue = 240;
        else if (MaxUserValue == 5000) keyMaxUserValue = 250;
        else keyMaxUserValue = 0;

        return keyMaxUserValue;
    }

    reset() {
        this.err = "";
        this.generatedLicense = undefined;
    }
}
