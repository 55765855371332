export default class TemplateService {
    constructor(private ApiService) {
    }

    getTemplateInfo(id: string) {
        return this.ApiService.get("/api/templates/" + id).then(r => r.data);
    }

    async deployTemplate(id: string, name: string) {
        return (await this.ApiService.post("/api/templates/" + id + "/deploy", {name})).data;
    }

    async deployDemoTemplate(id: string, name: string) {
        return (await this.ApiService.post("/api/templates/" + id + "/deployDemo", {name})).data;
    }
}
