import * as angular from "angular";
import LicensesPartnerCtrl from "./LicensesPartnerCtrl";

export default angular.module("inphinity.app.licenses.partner", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("licenses/partner", {
                url: "/licenses/partner?customer&computerId&note&company&email&version",
                template: require("./partner.html").default,
                controllerAs: "ctrl",
                controller: LicensesPartnerCtrl,
            })
    })
    .name;