import MessageService from "./MessageService";
import DialogService from "../dialogs/DialogService";

const NOTIFICATION_REFRESH_INTERVAL = 30000;

export default class NotificationDropdownCtrl {
    public messages = [];

    constructor(private MessageService: MessageService, private DialogService: DialogService, $interval, private $state, $scope) {
        console.log("notification setup");
        this.load();
        let timer = $interval(() => this.load(), NOTIFICATION_REFRESH_INTERVAL);
        $scope.$on("$destroy", () => {
            $interval.cancel(timer);
        })
    }

    async load() {
        this.messages = await this.MessageService.getNotifications();
    }

    getMessages() {
        return this.messages;
    }

    shortenMessage(text) {
        return text.split("\n")[0];
    }

    getUnseenCount() {
        return this.messages.filter(m => !m.seenAt).length;
    }

    seenAll() {
        this.messages.forEach(msg => this.MessageService.markSeen(msg.id))
    }

    async dismissAll() {
        await Promise.all(this.messages.map(msg => this.MessageService.markDismissed(msg.id)));
        this.load();
    }

    async open(msg) {
        this.MessageService.markSeen(msg.id);
        msg.seenAt = new Date();

        let confirmAction = null;
        if (msg.subtype == "ENDINGTRIAL") confirmAction = "Subscription management";
        if (msg.subtype == "LOWCREDITADMIN") confirmAction = "App management";
        if (msg.subtype == "MONTHLYRENEWAL" || msg.subtype == "LOWCREDITOWNER") confirmAction = "Billing";
        if (msg.subtype == "NEWTRIAL") confirmAction = "My workspace";

        const result = await this.DialogService.custom({
            title: msg.subject,
            text: msg.text,
            type: "info",
            confirmButtonText: confirmAction,
            showConfirmButton: !!confirmAction,
            showCancelButton: true,
            cancelButtonText: "Back",
        });
        if (result) {
            if (msg.subtype == "ENDINGTRIAL") this.$state.go("subscription");
            if (msg.subtype == "NEWTRIAL") confirmAction = this.$state.go("workspace");
            if (msg.subtype == "LOWCREDITADMIN") confirmAction = this.$state.go("app-management");
            if (msg.subtype == "MONTHLYRENEWAL" || msg.subtype == "LOWCREDITOWNER") confirmAction = this.$state.go("billing");

        }
        this.load();
    }
}
