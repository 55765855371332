import * as angular from "angular";
import UserManagementCtrl from "./UserManagementCtrl";
import UserService from "./UserService";

export default angular.module("inphinity.app.users", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("user-management", {
                url: "/users/manage",
                template: require("./user-management.html").default,
                controllerAs: "ctrl",
                controller: UserManagementCtrl,
                resolve: UserManagementCtrl.$resolve
            })
    })
    .service("UserService", UserService)
    .name;
