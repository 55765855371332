import * as angular from "angular";
import LoginCtrl from "./LoginCtrl";

const config = window["inphinity-config"];
const module = angular.module("inphinity.app.login", [])

if (config.useUnifiedLogin) {
    module.config(function routes($stateProvider) {
        $stateProvider
            .state("login", {
                url: "/login",
                template: require("./login.html").default,
                controller: LoginCtrl,
                controllerAs: "ctrl",
                resolve: LoginCtrl.$resolve
            })
    })
}

export default module.name;