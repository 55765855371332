import DialogService from "../dialogs/DialogService";
import ApiService from "../api/ApiService";

export default class WorkspaceCtrl {

    static $resolve = {
        apps: (AppService) => AppService.getApps().then(apps => apps.filter(app => !(app.subscription.appInstanceId == null)))
    }

    constructor(public apps, private AppService, private SettingsService, private config, private DialogService: DialogService, private ApiService: ApiService) {
    }

    async loadApps() {
        this.apps = await this.AppService.getApps().filter(app => !(app.subscription.appInstanceId == null));
    }

    open(app) {
        if (app.subscription.active == 0) {
            void this.DialogService.warning("Your app do not have active subscription.");
        } else if (app.permission.read) {
            window.open(app.url);
        } else {
            void this.DialogService.warning("You do not have access to this app.");
        }
    }

    download(app) {
        console.log("download app", app)
        void this.ApiService.postForm("/api/templates/" + app.template.id + "/download/" + app.template.name, null);
    }


    async resetForms(app) {
        let result = await this.DialogService.confirm("Reset forms data to default?");
        if (result) {
            try {
                await this.ApiService.post("/api/apps/" + app.id + "/resetForms", null);
                await this.DialogService.success("Forms data was reset.");
            } catch (e) {
                await this.DialogService.error("Unable to reset forms, please contact support.");
            }
        }

    }

    async changeFavouriteFlag(app) {
        var fav = [{
            userId: app.userId,
            appInstanceId: app.appInstanceId,
            favourite: app.favourite ? 0 : 1
        }];
        app.favourite = !app.favourite;
        await this.SettingsService.updateFavourites(fav);
        await this.loadApps();
    }
}
